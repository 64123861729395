import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EventModalComponent } from 'src/app/modals/event-modal/event-modal.component';
import { People } from 'src/app/services/mesh/mesh.models';
import { MeshService } from 'src/app/services/mesh/mesh.service';
import { Report, ReportsService } from 'src/app/services/reports/reports.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-people-view',
  templateUrl: './people-view.component.html',
  styleUrls: ['./people-view.component.scss']
})
export class PeopleViewComponent implements OnInit {

  person: People;
  personHistory: QueryResult<Report>;
  query: any;
  constructor(private route: ActivatedRoute, private meshService: MeshService,
    private reportsService: ReportsService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.query = {
      offset: 0,
      size: 10
    };

    this.route.paramMap.subscribe((params) => {
      if(params['params']['id']) {
        this.meshService.getPerson(params['params']['id']).subscribe((person) => {
          this.person = person;
          this.query['person_ids'] = [person._id];
          this.getPersonHistory();
        })
      }
    });
  }

  getPersonHistory() {
    this.reportsService.getPersonHistory(this.person._id, this.query).subscribe((history: QueryResult<Report>) => {
      this.personHistory = history;
    });
  }

  onEventSelected(index?: number) {
    const dialogRef = this.dialog.open(EventModalComponent, {
      panelClass: 'event-modal',
      backdropClass: 'people-create-backdrop',
      data: {
        event: this.personHistory.data[index],
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  save() { }

}
