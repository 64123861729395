import { Injectable } from '@angular/core';
import { ApiService, ApiEndpoint } from '../api/api.service';
import { Observable } from 'rxjs';

export class ProfileTimeline {
  image: string;
  timestamp: string;
}

export class Profile {
  id: string;
  name: string;
  timeline: ProfileTimeline[];
  images: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private api: ApiService) { }

  getProfiles() {
    const profileHandler = () => (source: Observable<any>) =>
      new Observable<Profile[]>(observer => {
        source.subscribe((result) => {
          if(result) {
            let profiles = [];
            result.forEach(element => {
              let p = new Profile();
              p.id = element['id'];
              p.name = element['name'];
              p.images = element['images'];

              let events = [];
              /*element['timeline'].forEach(t => {
                let e = new ProfileTimeline();
                e.image = t['image'];
                e.timestamp = t['timestamp'];
                events.push(e);
              })
              p.timeline = events;*/
              profiles.push(p);
            });

            observer.next(profiles);
          }
        }, error => observer.error(error));
      });
    return this.api.get(ApiEndpoint.PROFILE + '/profiles').pipe(profileHandler());
  }

  updateProfile(profile: Profile) {
    return this.api.post(ApiEndpoint.PLATFORM + '/update_profile', profile);
  }

  createProfile(fd: FormData) {
    return this.api.postForm(ApiEndpoint.PROFILE + '/profile', fd);
  }

  analyze(fd: FormData) {
    return this.api.postForm(ApiEndpoint.PLATFORM + '/image_analyzer', fd);
  }

  getAlerts() {
    return this.api.get(ApiEndpoint.PLATFORM + '/get_alerts');
  }
}
