import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryResult } from "src/app/utils/query-result";
import { Device } from "./device.models";
import { DeviceService } from "./device.service";

@Injectable()
export class MockDeviceService extends DeviceService {

    getDevices(query: any = {}) {
        return new Observable<QueryResult<Device>>(observer => {
            let deviceQueryResult = new QueryResult<Device>();
            deviceQueryResult.data = [];
            deviceQueryResult.data.push(
                { _id: '1', name: 'Device 1', acc_id: '01', primary_model: { name: 'object' }},
                { _id: '2', name: 'Device 2', acc_id: '02', primary_model: { name: 'object' }},
                { _id: '3', name: 'Device 3', acc_id: '03', primary_model: { name: 'object' }},
            );
            deviceQueryResult.total = deviceQueryResult.data.length;
            observer.next(deviceQueryResult);
        });
    }

    saveDevice() {
        return new Observable<Device>(observer => {
            observer.next({ _id: '1', name: 'Device 1', acc_id: '01', primary_model: { name: 'object' }});
        });
    }
}
