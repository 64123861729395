import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-regions-list',
  templateUrl: './regions-list.component.html',
  styleUrls: ['./regions-list.component.scss']
})
export class RegionsListComponent implements OnInit {

  regionQueryResult: QueryResult<Region>;
  constructor(private regionService: RegionService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.regionService.getRegions().subscribe((result: QueryResult<Region>) => {
      this.regionQueryResult = result;
    })
  }

  removeRegion(index: number) {
    this.regionService.deleteRegion(this.regionQueryResult.data[index]._id).subscribe(() => {
      this.regionQueryResult.data.splice(index, 1);
      this.snackBar.open('Region was successfully removed', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
