import { ApiService } from "../api/api.service";
import { EnvService } from "../env/env.service";
import { CameraService } from "./camera.service";
import { MockCameraService } from "./mock.camera.service";

export function CameraServiceFactory(api: ApiService, envService: EnvService) {
    if(envService.mock) {
        return new MockCameraService(api, envService);
    }
    return new CameraService(api, envService);
}