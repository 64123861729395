import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { ApiService } from '../api/api.service';
import { EventAttributes, EventRule } from './event-rules.model';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class EventRulesService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getEventAttribute(id: string) {
    return EventAttributes.filter((attribute) => {
      return attribute.id == id;
    })[0];
  }

  getEventRules(query: any = {}) {
    const eventRulesPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<EventRule>>(observer => {
        source.subscribe((result) => {
          let eventRulesQueryResult = result as QueryResult<EventRule>
          observer.next(eventRulesQueryResult);
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.PLATFORM + '/eventrule/query', query).pipe(eventRulesPipe())
  }

  deleteEventRule(id: string) {
    const eventRulesPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<EventRule>>(observer => {
        source.subscribe((result) => {
          observer.next();
        }, error => {
          observer.error(error);
        });
      });
    return this.api.delete(this.envService.PLATFORM + '/eventrule/' + id).pipe(eventRulesPipe())
  }

  applyEventRule(eventRuleId: string, cameraIds: string[]) {
    const eventRulesPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<EventRule>>(observer => {
        source.subscribe((result) => {
          observer.next();
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.PLATFORM + '/eventrule/apply/' + eventRuleId, cameraIds)
      .pipe(eventRulesPipe())
  }

  saveEventRule(eventRule: EventRule) {
    const eventRulesPipe = () => (source: Observable<any>) =>
      new Observable<EventRule>(observer => {
        source.subscribe((result) => {
          let eventRule = result as EventRule
          observer.next(eventRule);
        }, error => {
          observer.error(error);
        });
      });
    if(eventRule._id) {
      let url = this.envService.PLATFORM + '/eventrule/' + eventRule._id;
      return this.api.put(url, eventRule).pipe(eventRulesPipe())
    }
    return this.api.post(this.envService.PLATFORM + '/eventrule', eventRule).pipe(eventRulesPipe())
  }
}
