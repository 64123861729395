<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Alert Group</h1>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="save()">Save</button>
        </div>
    </div>

    <div id="page-content">
        <div>
            <div class="content-section" *ngIf="alertGroup">

                <div class="row input-field">
                    <div class="col-12">
                        <mat-form-field class="field">
                            <mat-label>Name</mat-label>
                            <input matInput [(ngModel)]="alertGroup.name">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row input-field">
                    <div class="col-12">
                        <mat-form-field class="field">
                            <mat-label>Notification Interval</mat-label>
                            <input matInput type="number" [(ngModel)]="alertGroup.notification_interval">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row input-field">
                    <div class="col-12">
                        <div>
                            <mat-label>Channel</mat-label>
                            <select class="app-select" [(ngModel)]="alertGroup.channel">
                                <option></option>
                                <!--<option value="user">Users</option>-->
                                <option value="email">Email</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row input-field" *ngIf=" alertGroup.channel=='email' ">
                    <div class="col-12">
                        <div class="header">
                            <mat-label>Emails</mat-label>
                            <mat-icon
                                class="example-icon"
                                (click)="addEmail()">add</mat-icon>
                        </div>
                        <div class="example-full-width">

                            <div id="group-emails"
                                *ngFor="let user of alertGroup.emails; let i = index; trackBy: trackByFn">
                                <mat-form-field class="field">
                                    <mat-label>Enter email</mat-label>
                                    <input matInput [(ngModel)]="alertGroup.emails[i]">
                                </mat-form-field>
                                <mat-icon
                                    class="example-icon"
                                    (click)="removeEmail(i)">delete</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>