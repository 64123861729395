<div class="page-header">
    <div id="title">
        <h1>Report</h1>
    </div>
</div>
<div class="page">
    <div class="row">
        <div class="col-12 panel">
            <div class="panel-body">
                <div class="row livefeed-search">
                    <div class="col-4">
                        <select
                            class="app-select"
                            (change)="onTypeSelected($event)">
                            <option value="frame">Alerts</option>
                            <option value="object">Objects</option>
                        </select>
                        <!--<mat-form-field id="tag-select">
                            <mat-chip-list #chipList aria-label="Tag selection">
                              <mat-chip
                                *ngFor="let tag of query.type"
                                selectable
                                removable
                                (removed)="remove(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                              <input
                                placeholder="Search tag..."
                                #tagInput
                                [formControl]="tagCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTypeSelected($event)">
                              <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                {{tag}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>-->
                    </div>

                    <div class="col-4">
                        <mat-form-field color="accent" id="query-start" class="querydate">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker1" (dateInput)="onDateSelect($event, 'start_date')"
                                [formControl]="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 color="primary"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field color="accent" id="query-end" class="querydate">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="picker2" (dateInput)="onDateSelect($event, 'end_date')"
                                [formControl]="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 color="primary"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="this.query.type[0] == 'object'">
        <div class="col-12 panel">
            <mat-form-field id="tag-select">
                <mat-chip-list #chipList aria-label="Tag selection">
                  <mat-chip
                    *ngFor="let tag of query.object_type"
                    selectable
                    removable
                    (removed)="remove(tag)">
                    {{tag}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Search tag..."
                    #tagInput
                    [formControl]="tagCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTypeSelected($event)">
                  <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                    {{tag}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-graph [(report)]="report"></app-graph>
        </div>
    </div>
</div>