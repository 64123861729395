import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [CommonModule, MatButtonModule,MatToolbarModule, MatSidenavModule, MatListModule, MatIconModule,
    MatMenuModule, MatFormFieldModule, MatChipsModule, MatAutocompleteModule, MatDatepickerModule, MatInputModule,
    MatTableModule, MatSlideToggleModule, MatExpansionModule, MatCheckboxModule, MatRadioModule,
    MatSnackBarModule],
  exports: [CommonModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatListModule, MatIconModule,
    MatMenuModule, MatFormFieldModule, MatChipsModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule,
    MatInputModule, MatTableModule, MatSlideToggleModule, MatExpansionModule, MatCheckboxModule, MatRadioModule,
    MatSnackBarModule],
})
export class CustomMaterialModule { }