import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {

  _totalPages: number;

  @Input()
  set totalPages(n: number) {
    if(n != undefined) {
      this._totalPages = n;
      this.generatePageNumbers();
    }
  }

  get totalPages() {
    return this._totalPages;
  }

  @Input()
  currentPage: number;

  @Output()
  change: EventEmitter<number> = new EventEmitter();

  pages: any[];

  constructor() {
    this.pages = Array(7);
  }

  ngOnInit(): void { }

  generatePageNumbers() {
    this.pages.splice(0, 7);
    if(this.currentPage == undefined) {
      this.currentPage = 1;
    }
    
    if(this.totalPages <= 7) {
      for(let i=0; i<this.totalPages; i++) {
        this.pages[i] = i+1;
      }
    }
    else {
      this.pages[0] = 1;
      this.pages[6] = this.totalPages;
      if(this.currentPage > 4) {
        this.pages[1] = 'el';

        if((this.currentPage+2)>=(this.totalPages-1)) {
          this.pages[2] = this.totalPages-4;
          this.pages[3] = this.totalPages-3;
          this.pages[4] = this.totalPages-2;
          this.pages[5] = this.totalPages-1;
        }
        else {
          this.pages[2] = this.currentPage-1;
          this.pages[3] = this.currentPage;
          this.pages[4] = this.currentPage+1;
          this.pages[5] = 'el';
        }
      }
      else {
        this.pages[1] = 2;
        this.pages[2] = 3;
        this.pages[3] = 4;
        this.pages[4] = 5;
        this.pages[5] = 'el';
      }

    }
  }

  next() {
    if (this.currentPage == this.totalPages) {
      return;
    }

    this.currentPage++;
    this.generatePageNumbers();
    this.change.emit(this.currentPage);
  }

  previous() {
    if (this.currentPage == 1) {
      return;
    }

    this.currentPage--;
    this.generatePageNumbers();
    this.change.emit(this.currentPage);
  }

  paginate(page) {
    this.currentPage = page;
    this.generatePageNumbers();
    this.change.emit(this.currentPage);
  }

}
