import { Component, OnInit } from '@angular/core';
import { Seen, SeenService } from 'src/app/services/seen/seen.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-seen-search',
  templateUrl: './seen-search.component.html',
  styleUrls: ['./seen-search.component.scss']
})
export class SeenSearchComponent implements OnInit {

  seenList: Seen[];
  constructor(private seenService: SeenService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      if (params['params']['id']) {
        this.seenService.searchSeen(params['params']['id']).subscribe((result) => {
          this.seenList = result;
        });
      }
    });
  }

}
