<div class="page-header">
    <div id="title">
        <h1>Regions</h1>
    </div>
</div>

<div class="page">
    <div class="row">
        <div class="col-12">
            <div class="content-section">
                <div class="app-table">
                    <div class="table-query">
                        <div class="col-2 offset-10">
                            <button class="app-button" routerLink="/settings/regions/create">Create</button>
                        </div>
                    </div>
                    <h1 class="no-data" *ngIf="!regionQueryResult || regionQueryResult.data.length == 0">No Regions</h1>
                    <div class="highlight-table" *ngIf="regionQueryResult">
                        <div class="row header">
                            <div class="col-4">Name</div>
                            <div class="col-4">Type</div>
                            <div class="col-4">Action</div>
                        </div>
                        <div *ngFor="let region of regionQueryResult.data; let i = index;">
                            <div class="row highlighted">
                                <div class="col-4">{{region.name}}</div>
                                <div class="col-4">{{region.type}}</div>
                                <div class="col-4">
                                    <mat-icon
                                        class="example-icon"
                                        [routerLink]="['/settings/regions', region._id]">edit</mat-icon>
                                        <mat-icon
                                        class="example-icon"
                                        (click)="removeRegion(i)">delete</mat-icon>
                                </div>
                            </div>
                            <div class="row spacer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>