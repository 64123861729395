<div class="page">

    <div id="timeline-details" class="row">
        <div class="col-4 panel">
            <div class="panel-body">
                <div class="chooser">
                    <img src="http://13.82.102.247/get_image?file={{profile.timeline[0].image}}"
                        *ngIf="profile && profile.timeline" />
                        
                    <h5>Select to choose a file</h5>
                    <input type="file" name="file" (change)="fileChange($event)" />
                </div>
            </div>
        </div>
        <div class="col-8 panel">

            <mat-form-field class="example-full-width" *ngIf="profile.id">
                <mat-label>ID</mat-label>
                <input matInput [formControl]="profileFormControl" [(ngModel)]="profile.id">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Name</mat-label>
                <input matInput [formControl]="profileFormControl" [(ngModel)]="profile.name" *ngIf="profile">
            </mat-form-field>
            
            <br/>
            <button class="app-button" (click)="save()">Submit</button>
        </div>
    </div>
</div>