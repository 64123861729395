<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Cameras</h1>
            <h2>Create and manage your cameras.</h2>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="createCamera()">Create</button>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <div class="highlight-table container" *ngIf="cameraQueryResult">
                <div class="row header">
                    <div class="col-2">Name</div>
                    <div class="col-2">Status</div>
                    <div class="col-2">Size</div>
                    <div class="col-2">Device</div>
                    <div class="col-2">Rule Types(s)</div>
                    <div class="col-2">Actions</div>
                </div>
                <div  id="table-data">
                    <div *ngFor="let camera of cameraQueryResult.data; let i = index;">
                        <div class="row highlighted">
                            <div class="col-2">{{camera.name}}</div>
                            <div class="col-2">
                                <mat-slide-toggle
                                    color="primary"
                                    [checked]="camera.enabled" (change)="onCameraStatusChange($event, i)">
                                    <span *ngIf="!camera.enabled">Disabled</span>
                                    <span *ngIf="camera.enabled">Enabled</span>
                                </mat-slide-toggle>
                            </div>
                            <div class="col-2">{{camera.dimensions[0]}} x {{camera.dimensions[1]}}</div>
                            <div class="col-2">
                                <span *ngIf="devices && devices[camera._id]">
                                    {{devices[camera._id]['name']}}
                                </span>
                            </div>
                            <div class="col-2">
                                {{ eventRulesAttributes[camera._id] }}
                            </div>
                            <div class="col-2 actions">
                                <button
                                    class="action-item"
                                    [routerLink]="['/settings/cameras', camera._id]">
                                    <img src="../../../assets/images/icons/edit.svg" />
                                    <span>Edit</span>
                                </button>

                                <button
                                    class="action-item"
                                    (click)="deleteCamera(i)">
                                    <img src="../../../assets/images/icons/trash.svg" />
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>
</div>