import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Profile, ProfileService } from 'src/app/services/profile/profile.service';
import { FormControl, Validators } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  profile: Profile;
  file: any;

  profileFormControl = new FormControl('', [
    Validators.required
  ]);

  constructor(private router: Router, private profileService: ProfileService,
    private route: ActivatedRoute, private snackBar: MatSnackBar) {
    if (this.router.getCurrentNavigation().extras.state) {
      const state = this.router.getCurrentNavigation().extras.state;
      this.profile = state.profile;
    } else {
      let id = this.route.snapshot.paramMap.get('id');
      if (id) {
        this.profileService.getProfiles().subscribe(profiles => {
          for (var i = 0; i < profiles.length; i++) {
            if (profiles[i].id == id) {
              this.profile = profiles[i];
              break;
            }
          }
        })
      } else {
        this.profile = new Profile();
      }
    }
  }

  ngOnInit(): void {
  }

  save() {
    if(this.profile.id) {
      this.profileService.updateProfile(this.profile).subscribe(result => {
        
      });
    } else {
      let fileList: FileList = this.file;
      if (fileList && fileList.length > 0) {
        let file: File = fileList[0];
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', this.profile.name);
        this.profileService.createProfile(formData).subscribe(result => {
          this.snackBar.open('Profile was successfully created.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, error => {
          this.snackBar.open('Profile could not be created. Please try again later.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    }
  }

  fileChange(event) {
    this.file = event.target.files;
  }

}
