import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export enum ApiEndpoint {
  PLATFORM = 'http://13.82.102.247',
  PROFILE = 'http://13.82.102.247:5010',
  MIDDLE = 'http://13.82.102.247:5018',
  //MIDDLE = 'http://3.210.181.167:5018',
  REPORT = 'http://13.82.102.247:5011',
  STATIC = 'http://13.82.102.247:5001/images/',
  ACCOUNT = 'http://13.82.102.247:5016'
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  post(url: string, body: any, httpOptions?: any): Observable<any> {
    return this.http
      .post(url, JSON.stringify(body), httpOptions? httpOptions: this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  get(url: string): Observable<any> {
    return this.http
      .get(url)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  put(url: string, body: any): Observable<any> {
    return this.http
      .put(url, JSON.stringify(body), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  delete(url: string) {
    return this.http
      .delete(url, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  postForm(url: string, body: any, httpOptions?: any): Observable<any> {
    return this.http
      .post(url, body)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
}