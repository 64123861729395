import { ApiService } from "../api/api.service";
import { EnvService } from "../env/env.service";
import { DeviceService } from "./device.service";
import { MockDeviceService } from "./mock.device.service";

export function DeviceServiceFactory(api: ApiService, envService: EnvService) {
    if(envService.mock) {
        return new MockDeviceService(api, envService);
    }
    return new DeviceService(api, envService);
}