import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Camera } from 'src/app/services/camera/camera.model';
import { EventRule } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-event-rules-list',
  templateUrl: './event-rules-list.component.html',
  styleUrls: ['./event-rules-list.component.scss']
})
export class EventRulesListComponent implements OnInit {

  eventRulesQueryResult: QueryResult<EventRule>;
  constructor(private eventRulesService: EventRulesService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.eventRulesService.getEventRules().subscribe((queryResult) => {
      this.eventRulesQueryResult = queryResult;
    })
  }

  deleteEventRule(i: number) {
    this.eventRulesService.deleteEventRule(this.eventRulesQueryResult.data[i]._id).subscribe(() => {
      this.eventRulesQueryResult.data.splice(i, 1);
      this.snackBar.open('Event rule was successfully removed', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
