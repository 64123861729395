import { Component, OnInit } from '@angular/core';
import { Seen, SeenService } from 'src/app/services/seen/seen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seen',
  templateUrl: './seen.component.html',
  styleUrls: ['./seen.component.scss']
})
export class SeenComponent implements OnInit {

  seenList: Seen[];
  constructor(private seenService: SeenService, private router: Router) { }

  ngOnInit(): void {
    this.seenService.getSeen().subscribe((result) => {
      this.seenList = result;
    });
  }

}
