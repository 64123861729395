<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>People</h1>
            <h2>Create and manage people and their profiles.</h2>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="createPerson()">Create</button>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <h1 class="no-data" *ngIf="!people || people.data.length == 0">No People</h1>
            <div class="highlight-table container" *ngIf="people && people.data.length > 0">
                <div class="row header">
                    <div class="col-2">Last Seen</div>
                    <div class="col-2">Name</div>
                    <div class="col-2">Image(s)</div>
                    <div class="col-2">No. of Clips Seen In</div>
                    <div class="col-2">Status</div>
                    <div class="col-2">Action</div>
                </div>
                <div  id="table-data">
                    <div *ngFor="let person of people.data; let i = index;">
                        <div class="row highlighted">
                            <div class="col-2">
                                n/a
                            </div>
                            <div class="col-2">
                                {{ person.name }}
                            </div>
                            <div class="col-2">
                                <div class="person-images">
                                    <div class="person-image" *ngFor="let img of person.images">
                                        <img src="{{img}}" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                0
                            </div>
                            <div class="col-2">
                                <span class="people-status indexed" *ngIf="person.status=='INDEXED'">Indexed</span>
                                <span class="people-status inprogress" *ngIf="person.status=='INDEXING_IN_PROGRESS'">In Progress</span>
                                <span class="people-status failed" *ngIf="person.status=='INDEXING_FAILED'">Failed</span>
                            </div>
                            <div class="col-2 actions">
                                <button
                                    class="action-item"
                                    [routerLink]="['/people', person._id]">
                                    <img src="../../../assets/images/icons/edit.svg" />
                                    <span>Edit</span>
                                </button>

                                <button
                                    class="action-item"
                                    (click)="deletePerson(i)">
                                    <img src="../../../assets/images/icons/trash.svg" />
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>
</div>