<mat-sidenav-container class="side-menu">
    <mat-sidenav #sidenav mode="side" opened *ngIf="config.isMenuActive && isLoggedIn">
        <div id="sidebar-holder">
            <div id="sidebar-logo">
                <img class="logo" src="../assets/images/logo.png" *ngIf="config.isDarkMode" />
                <img class="logo" src="../assets/images/logo-dark.png" *ngIf="!config.isDarkMode" />
            </div>
            <div class="sidemenu-list">
                <a routerLink="{{link.path}}" *ngFor="let link of menuLinks"
                    class="menu-item">
                    <mat-icon matListIcon>{{link.icon}}</mat-icon>
                    <h3> {{link.title}} </h3>
                </a>
            </div>

            <div id="navbar-footer">
                <div id="settings">
                    <div class="sidemenu-list">
                        <a routerLink="/settings" class="menu-item">
                            <mat-icon matListIcon>settings</mat-icon>
                            <h3>Settings</h3>
                        </a>

                        <!-- <a routerLink="/settings" class="menu-item">
                            <mat-icon matListIcon>settings</mat-icon>
                            <h3>Support</h3>
                        </a> -->
                    </div>
                </div>
                <hr />
                <div id="account">
                    <div class="sidemenu-list">
                        <a routerLink="#" class="menu-item" (click)="logout()">
                            <mat-icon matListIcon>logout</mat-icon>
                            <h3>Logout</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div id="toolbar" *ngIf="config.isMenuActive && isLoggedIn">
            <div id="options">
                <!--<div class="options-item">
                    <span id="sidemenu-collapse">Collapse Sidebar</span>
                </div>-->
                <div id="darkmode" class="options-item" (click)="toggleDarkMode()">
                    <img src="../assets/images/icons/moon-dark.svg" *ngIf="config.isDarkMode">
                    <img src="../assets/images/icons/moon-grey.svg" *ngIf="!config.isDarkMode">
                </div>
            </div>
            <div id="options">
                <div class="options-item profile">
                    <span *ngIf="user">{{user.name}}</span>
                    <div id="profilepic-thumbnail">
                        <img src="https://t4.ftcdn.net/jpg/04/77/12/75/360_F_477127527_5sbHIfI45c6yMr9zPwNhkclf0v58fzNE.jpg" />
                    </div>
                </div>
            </div>
        </div>

        <div id="content" [ngClass]="{'content-margin': config.isMenuActive && isLoggedIn}">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>