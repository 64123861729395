<div class="page livefeed">

    <div class="row">
        <div class="col-6 panel">
            <div class="panel-body">
                
                <div class="page-header">
                    <h5>Analyzer</h5>
                    <span>Analyze videos and images</span>
                </div>

                <div class="analyzer-settings">

                    <div class="row">
                        <div class="col-6">
                            <div id="file-chooser">
                                <label for="content">Select image</label>
                                <input type="file" id="image_file" name="file" (change)="fileChange($event)" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div>
                                <button class="clear">Clear Fields</button>
                                <button class="analyze">Analyze</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="analyzer-results">
                <table id="history">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Timestamp</th>
                            <th>Action</th>
                        </tr>
                        <tr class="spacer"></tr>
                    </thead>
                    <tbody>
                        <tr class="highlighted">
                            <td>People, Cars, Bags</td>
                            <td>Dec 2, 2017 2:39:58 AM</td>
                            <td>CAM 1</td>
                        </tr>
                        <tr class="spacer"></tr>
                        <tr class="highlighted">
                            
                            <td>People, Cars, Bags</td>
                            <td>Dec 2, 2017 2:39:58 AM</td>
                            <td>CAM 1</td>
                        </tr>
                        <tr class="spacer"></tr>
                        <tr class="highlighted">
                            
                            <td>People, Cars, Bags</td>
                            <td>Dec 2, 2017 2:39:58 AM</td>
                            <td>CAM 1</td>
                        </tr>
                        <tr class="spacer"></tr>
                        <tr class="highlighted">
                            
                            <td>People, Cars, Bags</td>
                            <td>Dec 2, 2017 2:39:58 AM</td>
                            <td>CAM 1</td>
                        </tr>
                        <tr class="spacer"></tr>
                        <tr class="highlighted">
                        
                            <td>People, Cars, Bags</td>
                            <td>Dec 2, 2017 2:39:58 AM</td>
                            <td>CAM 1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-6">
            <div class="analyzer-view">
                <img id="placeholder" src="assets/images/uploadimage.png" />
            </div>
        </div>
    </div>
</div>