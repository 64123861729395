import { Component, OnInit } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { Router } from '@angular/router';
import { QueryResult } from 'src/app/utils/query-result';
import { Camera } from 'src/app/services/camera/camera.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService } from 'src/app/services/device/device.service';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { EventRule } from 'src/app/services/event-rules/event-rules.model';
import { MatDialog } from '@angular/material/dialog';
import { CameraConfigComponent } from '../create/camera-config.component';
import { ConfirmDialogModel } from 'src/app/modals/confirm-dialog/confirm.model';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-camera-settings',
  templateUrl: './camera-settings.component.html',
  styleUrls: ['./camera-settings.component.scss']
})
export class CameraSettingsComponent implements OnInit {

  totalPages: number;
  pageSize: number;
  currentPage: number;
  query: any = {};

  cameraQueryResult: QueryResult<Camera>;
  devices: any;
  eventRulesAttributes: any = {};
  constructor(private cameraService: CameraService,
    private eventRulesService: EventRulesService,
    private snackBar: MatSnackBar, private dialog: MatDialog,
    private deviceService: DeviceService) { }

  ngOnInit(): void {
    this.pageSize = 10;
    this.currentPage = 1;
    this.query = {
      limit: this.pageSize,
      page: this.currentPage
    };
    this.devices = {};
    this.getCameras();
  }

  getCameras() {
    this.query['page'] = (this.currentPage-1);
    this.cameraService.getCameras(this.query).subscribe((result: QueryResult<Camera>) => {
      this.cameraQueryResult = result;
      this.generateEventRulesDisplay();
      this.generateDeviceDisplay();
      this.totalPages = Math.ceil(result['total']/this.pageSize);
    });
  }

  createCamera() {
    const dialogRef = this.dialog.open(CameraConfigComponent, {
      panelClass: 'people-create-modal',
      backdropClass: 'people-create-backdrop'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.cameraService.getCameras().subscribe((result: QueryResult<Camera>) => {
          this.cameraQueryResult = result;
          this.generateEventRulesDisplay();
        })
      }
    });
  }

  generateDeviceDisplay() {
    this.cameraQueryResult.data.forEach((camera) => {
      if(!this.devices[camera._id]) {
        this.deviceService.getDevices({'_id': camera.leaf_id}).subscribe((device) => {
          if(device.total>0) {
            this.devices[camera._id] = device.data[0];
          }
        })
      }
    });
  }

  generateEventRulesDisplay() {
    let p = []
    this.cameraQueryResult.data.forEach((camera) => {
      p.push(this.eventRulesService.getEventRules({ camera_id: camera._id }));
    });

    let resolveLabels = (labels) => {
      labels = labels.map((label: any) => {
        switch(label) {
          case 'count':
            return 'Overcrowding';
          case 'line':
            return 'Line Crossing';
          case 'face':
            return 'Facial Recognition';
          case 'loitering':
            return 'Loitering';
          case 'outzone':
            return 'Out Zone';
        }
      });
      let end = labels.splice(2);
      labels = labels.join(', ');
      if(end.length > 0) {
        labels += ' + ' + end.length + ' more';
      }
      return labels;
    };

    Promise.all(p).then((res) => {
      res.forEach((ruleObservable) => {
        ruleObservable.subscribe((data: QueryResult<EventRule>) => {
          if(data.total==0) {
            return '';
          }
          else {
            this.eventRulesAttributes[data.data[0]['camera_id']] = resolveLabels(data.data.map((rule: any) => {
              return rule['matches']['criteria'][0]['attribute'];
            }));
          }
        });
      })
    })
  }

  onPaginate($event: any) {
    this.currentPage = $event;
    this.getCameras();
  }

  onCameraStatusChange($event: any, index: number) {
    let camera = this.cameraQueryResult.data[index];
    camera.enabled = $event['checked'];
    this.cameraService.saveCamera(camera).subscribe(() => {
      this.snackBar.open('Camera was successfully updated', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    }, () => {
      this.snackBar.open('Camera could not be updated. Please try again.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

  deleteCamera(index: number) {
    const message = `Are you sure you want to do this?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.cameraService.deleteCamera(this.cameraQueryResult.data[index]._id).subscribe(() => {
          this.cameraQueryResult.data.splice(index, 1);
          this.snackBar.open('Camera was successfully removed', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        });
      }
    });
  }

}
