import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-camera-table',
  templateUrl: './camera-table.component.html',
  styleUrls: ['./camera-table.component.scss']
})
export class CameraTableComponent implements OnInit {

  totalPages: number;
  pageSize: number;
  currentPage: number;
  query: any = {};
  selectedCameras: Camera[];

  cameraQueryResult: QueryResult<Camera>;
  constructor(private cameraService: CameraService,
    private dialogRef: MatDialogRef<CameraTableComponent>) { }

  ngOnInit(): void {
    this.selectedCameras = [];
    this.pageSize = 5;
    this.currentPage = 1;
    this.query = {
      limit: this.pageSize,
      page: this.currentPage
    };
    this.getCameras();
  }

  getCameras() {
    this.query['page'] = (this.currentPage-1);
    this.cameraService.getCameras(this.query).subscribe((result: QueryResult<Camera>) => {
      this.cameraQueryResult = result;
      this.totalPages = Math.ceil(result['total']/this.pageSize);
    })
  }

  onPaginate($event: any) {
    this.currentPage = $event;
    this.getCameras();
  }

  onCameraSelected($event: any, id: string) {
    if($event.checked) {
      let filter = this.cameraQueryResult.data.filter((camera)=>camera._id==id);
      this.selectedCameras.push(filter[0]);
    }
    else {
      this.selectedCameras = this.selectedCameras.filter((camera)=>camera._id!=id);
    }
    console.log(this.selectedCameras);
  }

  cancel( ){
    this.dialogRef.close();
  }

  select() {
    this.dialogRef.close({"cameras": this.selectedCameras});
  }

  isCameraSelected(id: string) {
    return (this.selectedCameras.filter((camera)=>camera._id==id)).length>0;
  }

}
