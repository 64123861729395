import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { EnvService } from 'src/app/services/env/env.service';
import { QueryResult } from 'src/app/utils/query-result';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getReports(query: any = {}) {
    const reportPipe = () => (source: Observable<any>) =>
      new Observable<any>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.REPORT + '/v1/report', query).pipe(reportPipe())
  }
}
