import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { People } from 'src/app/services/mesh/mesh.models';
import { MeshService } from 'src/app/services/mesh/mesh.service';

@Component({
  selector: 'app-people-create',
  templateUrl: './people-create.component.html',
  styleUrls: ['./people-create.component.scss']
})
export class PeopleCreateComponent implements OnInit {

  name: string;
  person: People;
  selectedImages: any[] = [];
  files: any[] = [];
  isSaving: boolean = false;

  constructor(public dialogRef: MatDialogRef<PeopleCreateComponent>,
    private meshService: MeshService, private snackBar: MatSnackBar) {
    this.person = new People();
  }

  ngOnInit(): void { }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      this.files.push(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.selectedImages.push(event.target.result);
      }
    }
  }

  save() {
    if(this.files.length>0 && this.name && this.name!='') {
      const formData =  new  FormData();
      this.files.forEach((file) => {
        formData.append("photos",  file);
      });
      formData.append("name",  this.name);

      this.isSaving = true;
      this.meshService.savePerson(formData).subscribe(() => {
        this.isSaving = false;
        this.snackBar.open('Person was successfully saved', 'dismiss', {
          duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
        });
        this.cancel();
      }, (error) => {
        this.isSaving = false;
        this.snackBar.open('Person could not be saved. Please try again.', 'dismiss', {
          duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
        });
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
