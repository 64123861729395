<div class="graph-holder">
  <ngx-charts-bar-horizontal
    [scheme]="colorScheme"
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showGridLines]="showGridLines"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xScaleMax]="100"
    [xScaleMin]="0"
    [xAxisTicks]="xAxisTicks"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-horizontal>
</div>