<div id="confirm-holder">
    <h1 mat-dialog-title>
        {{title}}
    </h1>

    <div mat-dialog-content>
        <p>{{message}}</p>
    </div>

    <div mat-dialog-actions id="actions">
        <button mat-button (click)="onDismiss()">No</button>
        <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
    </div>
</div>