<div class="page livefeed">
    <div class="page-header">
        <h5>Profiles</h5>
        <span>All created and generated profiles</span>
    </div>
    <div class="row">
        <div class="col-12 panel">
            <div class="panel-body">
                <div class="row profile-search">
                    <div class="col-2">
                        <button routerLink="/profile/edit">Add Profile</button>
                    </div>

                    <div class="col-4">
                        <select>
                            <option></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" id="history">
            <h1 class="no-data" *ngIf="!profiles || profiles.length == 0">No Profiles</h1>
            <div class="row header" *ngIf="profiles && profiles.length > 0">
                <div class="col-2"></div>
                <div class="col-2">Name</div>
                <div class="col-4">Timestamp</div>
                <div class="col-2">Cam</div>
                <div class="col-2">Action</div>
            </div>
            <div *ngFor="let profile of profiles">
                <div class="row highlighted">
                    <div class="col-2">
                        <img src="http://13.82.102.247:5010/get_image?type=person&file={{profile.images[0]}}" />
                    </div>
                    <div class="col-2">{{profile.name}}</div>
                    <div class="col-4">Dec 2, 2017 2:39:58 AM</div>
                    <div class="col-2">Cam 1</div>
                    <div class="col-2">Action</div>
                </div>
                <div class="row spacer">
                </div>
            </div>
        </div>
    </div>
</div>