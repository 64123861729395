import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Device, DeviceModel } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';

@Component({
  selector: 'app-device-update',
  templateUrl: './device-update.component.html',
  styleUrls: ['./device-update.component.scss']
})
export class DeviceUpdateComponent implements OnInit {

  device: Device;
  selectedModelId: string;
  selectedModel: any;
  models: any[] = [
    {
      'id': 'object',
      'name': 'Object Detection',
      'description': 'Generate analytics for sections of a camera that you define on objects seen, overcrowding detection, direction detection and line crossing'
    },
    {
      'id': 'analytics',
      'name': 'Analytics',
      'description': 'Generate analytics for sections of a camera that you define on objects seen, overcrowding detection, direction detection and line crossing'
    },
    {
      'id': 'facedetect',
      'name': 'Face Detection',
      'description': 'Generate analytics for sections of a camera that you define on objects seen, overcrowding detection, direction detection and line crossing'
    },
    {
      'id': 'facerec',
      'name': 'Facial Recognition',
      'description': 'Detect and recognize faces of persons.'
    },
    {
      'id': 'lprec',
      'name': 'License Plate Recognition',
      'description': 'Detect and recognize license plate of vehicles.'
    }
  ]

  constructor(private route: ActivatedRoute, private deviceService: DeviceService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if(params['params']['id']) {
        this.deviceService.getDevices({'_id': params['params']['id']}).subscribe((deviceQueryResult) => {
          if(deviceQueryResult.total > 0) {
            this.device = deviceQueryResult.data[0];

            if(this.device.primary_model) {
              this.selectedModelId = this.device.primary_model.name;
            }
            else {
              this.selectedModelId = this.models[0]['id'];
            }
            this.onModelSelected();
          }
        })
      }
    });
  }

  save() {
    this.deviceService.saveDevice(this.device).subscribe((device) => {
      this.device = device;
      this.snackBar.open('Device was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    },
    (err) => {
      this.snackBar.open('Device could not be saved. Please try again.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

  onModelSelected() {
    this.selectedModel = this.models.filter((model) => {
      return model['id'] == this.selectedModelId;
    })[0];
    
    if(!this.device.primary_model) {
      this.device.primary_model = new DeviceModel();
    }

    this.device.primary_model.name = this.selectedModel['id'];

  }

}
