import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryResult } from "src/app/utils/query-result";
import { Camera } from "./camera.model";
import { CameraService } from "./camera.service";

@Injectable()
export class MockCameraService extends CameraService {

    getCameras(query: any = {}) {
        return new Observable<QueryResult<Camera>>(observer => {
            let deviceQueryResult = new QueryResult<Camera>();
            deviceQueryResult.data = [];
            deviceQueryResult.data.push(
                {
                    _id: '1', name: 'Camera 1', acc_id: '01', leaf_id: '01',
                    dimensions: [1024, 768], uri: '', enabled: true},
                {
                    _id: '2', name: 'Camera 2', acc_id: '02', leaf_id: '02',
                    dimensions: [1024, 768], uri: '', enabled: true}
            );
            deviceQueryResult.total = deviceQueryResult.data.length;
            observer.next(deviceQueryResult);
        });
    }

    saveCamera(camera: Camera) {
        return new Observable<Camera>(observer => {
            observer.next(camera);
        });
    }
}