import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { ApiService } from '../api/api.service';
import { Device } from './device.models';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getDevices(query: any = {}) {
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<Device>>(observer => {
        source.subscribe((result) => {
          let deviceQueryResult = result as QueryResult<Device>
          observer.next(deviceQueryResult);
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.PLATFORM + '/device/query', query).pipe(devicePipe())
  }

  saveDevice(device: Device) {
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<Device>(observer => {
        source.subscribe((result) => {
          let device = result as Device
          observer.next(device);
        }, error => {
          observer.error(error);
        });
      });
    if(device._id) {
      let url = `${this.envService.PLATFORM}/device/${device._id}`;
      return this.api.put(url, device).pipe(devicePipe())  
    }
    return this.api.post(this.envService.PLATFORM + '/device', device).pipe(devicePipe())
  }

  deleteDevice(device: Device) {
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<Device>(observer => {
        source.subscribe((result) => {
          let device = result as Device
          observer.next(device);
        }, error => {
          observer.error(error);
        });
      });
    let url = `${this.envService.PLATFORM}/device/${device._id}`;
    return this.api.delete(url).pipe(devicePipe())
  }

  generateConfig(device: Device) {
    let url = this.envService.GOTHAM + '/config/generate/' + device._id;
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<boolean>(observer => {
        source.subscribe(() => {
          observer.next(true);
        }, error => {
          observer.error();
        });
      });
    return this.api.get(url).pipe(devicePipe())
  }

  updateApplication(device: Device) {
    let url = this.envService.GOTHAM + '/sendkafka';
    let body = {
      "command": "app-update",
      "device_id": device._id
    };
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<boolean>(observer => {
        source.subscribe(() => {
          observer.next(true);
        }, error => {
          observer.error();
        });
      });
    return this.api.post(url, body).pipe(devicePipe())
  }

  updateObjectModel(device: Device) {
    let url = this.envService.GOTHAM + '/sendkafka';
    let body = {
      "command": "object-model",
      "device_id": device._id
    };
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<boolean>(observer => {
        source.subscribe(() => {
          observer.next(true);
        }, error => {
          observer.error();
        });
      });
    return this.api.post(url, body).pipe(devicePipe())
  }

  viewLogs(device: Device) {
    let url = this.envService.GOTHAM + '/sendkafka';
    let body = {
      "command": "logs",
      "device_id": device._id
    };
    const devicePipe = () => (source: Observable<any>) =>
      new Observable<boolean>(observer => {
        source.subscribe(() => {
          observer.next(true);
        }, error => {
          observer.error();
        });
      });
    return this.api.post(url, body).pipe(devicePipe())
  }

  getDeviceStatus(id: string) {
    let url = this.envService.GOTHAM + '/heartbeat/query';

    const devicePipe = () => (source: Observable<any>) =>
      new Observable<any[]>(observer => {
        source.subscribe((result) => {
          if(!result['data']) {
            observer.next([]);
            return;
          }

          result['data'] = result['data'].sort((item1: any, item2: any) => {
            return (new Date(item2['timestamp'])).getTime() - (new Date(item1['timestamp'])).getTime();
          });
          observer.next(result);
        }, error => {
          observer.error();
        });
      });
    return this.api.post(url, {"device_id": id}).pipe(devicePipe())
  }
}
