<div class="camera">
    <div class="camera-cover">
        <img src="{{camera.thumbnail}}" onerror="this.src='../../../assets/images/no-image.jpeg'; " />
    </div>
    <div class="details" *ngIf="descriptionEnabled">
        <div class="details-row">
            <div>
                <span id="camera-name">{{ camera.name }}</span>
            </div>
            <div>
                <span
                    class="offline" id="camera-status"
                    *ngIf="!deviceStatus || deviceStatus.total==0">
                    <span id="indicator"></span>
                    Offline
                </span>
            
                <span
                    class="online" id="camera-status"
                    *ngIf="deviceStatus && deviceStatus.total>0">
                    <span id="indicator"></span>
                    Online
                </span>
            </div>
        </div>
        <div class="details-row">
            <div id="event-label">
                <span>Detected Events</span>
            </div>
            <div id="event-description">
                <span class="sub" *ngIf="!cameraStatus">No recent events</span>
                <span class="sub event" *ngIf="cameraStatus">
                    <span>{{ cameraStatus.attribute }}</span>
                </span>
            </div>
        </div>
    </div>

    <!--<div id="alert-badges" *ngIf="descriptionEnabled">
        <img src="../../../assets/images/badges/overcrowding.svg" />
    </div>-->
</div>