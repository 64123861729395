import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { User } from 'src/app/services/user/user.model';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  page: number = 0;
  limit: number = 10;
  userQuery: QueryResult<User>;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.userService.getUsers({}, this.page, this.limit).subscribe((userQuery) => {
      this.userQuery = userQuery;
    })
  }

  editUser(index: number) {
    this.router.navigate(['/user/' + this.userQuery.data[index]._id], {
      state: {
        user: this.userQuery.data[index]
      }
    });
  }

  deleteUser(i: number) {
    this.userService.deleteUser(this.userQuery.data[i]._id).subscribe(() => {
      this.userQuery.data.splice(i, 1);
    });
  }

}
