<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Live Tracker</h1>
            <h2>Live analysis tracker</h2>
        </div>
        <div>
            <app-select
                [label]="'Cameras'"
                [options]="cameraOptions"
                (valueChange)="onCameraChange($event)"></app-select>
        </div>
    </div>

    <div id="page-content">
        <div id="live-view">
            <iframe [src]="url" scrolling="no"></iframe>
            <!--<div id="cameras">
                <div *ngFor="let camera of cameras.data; let i = index">
                    <app-camera
                        (click)="selectCamera(i)"
                        [camera]="camera" [descriptionEnabled]="false"></app-camera>
                </div>
            </div>-->
        </div>

        <div id="event-alerts">
            <div id="events-holder">
                <div class="event-item" *ngFor="let event of events; let i = index">
                    <div class="face-event-item" *ngIf="event.type=='face-rec'">
                        <div class="face-image">
                            <div>
                                <img src="{{event.data.images[0]}}" />
                            </div>

                            <div>
                                <img src="{{event.data.base_images[0]}}" />
                            </div>
                        </div>
                        <div class="face-description">
                            <span id="person-name">
                                <a href="/people/{{event.data.profile_id}}">{{ event.data.person.person_name }}</a>
                            </span>
                            <span id="person-prob">{{ ((event.data.person.person_prob/2)*100).toFixed(2) }}% Match</span>
                            <span id="event-date">{{event.data.timestamp | date:'medium'}}</span>
                        </div>
                    </div>

                    <div class="face-event-item" *ngIf="event.type=='face-detect'">
                        <div class="face-image">
                            <div>
                                <img src="{{event.data.images[0]}}" />
                            </div>

                            <div></div>
                        </div>
                        <div class="face-description">
                            <span id="person-name">Unknown</span>
                            <span id="event-date">{{event.data.person.gender}}</span>
                            <span id="event-date">{{event.data.person.age}}</span>
                            <span id="event-date">{{event.data.timestamp | date:'medium'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>