<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Event Rules</h1>
            <h2>Create and manage event rule templates.</h2>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="createEventRule()">Create Rule</button>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <div class="highlight-table container" *ngIf="rulesQueryResult">
                <div class="row header">
                    <div class="col-3">Description</div>
                    <div class="col-2">Attribute</div>
                    <div class="col-2">Type</div>
                    <div class="col-2">Criteria Actions</div>
                    <div class="col-3">Actions</div>
                </div>
                <div id="table-data" *ngIf="rulesQueryResult">
                    <div class="row highlighted" *ngFor="let rule of rulesQueryResult.data; let i = index">
                        <div class="col-3">{{rule.description}}</div>
                        <div class="col-2">{{eventRulesAttributes[rule._id]['attributes']}}</div>
                        <div class="col-2">{{eventRulesAttributes[rule._id]['types']}}</div>
                        <div class="col-2">{{eventRulesAttributes[rule._id]['actions']}}</div>
                        <div class="col-3 actions">
                            <button
                                class="action-item"
                                (click)="createEventRule(i)">
                                <img src="../../../assets/images/icons/edit.svg" />
                                <span>Edit</span>
                            </button>

                            <button
                                class="action-item"
                                (click)="deleteEventRule(i)">
                                <img src="../../../assets/images/icons/trash.svg" />
                                <span>Delete</span>
                            </button>

                            <button
                                class="action-item"
                                (click)="applyEventRule(i)">
                                <img src="../../../assets/images/icons/circle-add.svg" />
                                <span>Use Rule</span>
                            </button>
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>
</div>