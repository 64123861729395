import { Component, Input, OnInit } from '@angular/core';
import { EventRuleCriteria } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { Report } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-event-description',
  templateUrl: './event-description.component.html',
  styleUrls: ['./event-description.component.scss']
})
export class EventDescriptionComponent implements OnInit {

  _event: Report;

  @Input()
  set event(event: Report) {
    if(event) {
      this._event = event;
      
      // if probability score is in meta, round to the nearest 2 decimals
      if(this._event.meta['person_prob']) {
        this.event.meta.person_prob = parseFloat(this.event.meta.person_prob).toFixed(2);
      }
      this.eventRulesService.getEventRules({'_id': event.event_rule_id}).subscribe((eventRule) => {
        if(eventRule.total > 0) {
          let criteria = eventRule.data[0].matches.criteria.filter((criteria) => {
            if (criteria.id = event.criteria_id) {
              return criteria;
            }
          });

          if(criteria.length > 0) {
            this.eventCriteria = criteria[0];
          }
        }
      });
    }
  }

  get event() {
    return this._event;
  }

  eventCriteria: EventRuleCriteria;

  constructor(private eventRulesService: EventRulesService) { }

  ngOnInit(): void { }

}
