import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements OnInit {

  // fa icons
  faloading = faCircleNotch;

  @Input()
  loading: boolean;

  @Input()
  text: string;

  constructor() {
    this.loading = false;
    this.text = ''
  }

  ngOnInit(): void { }

}
