<div id="people-create-holder">
    <div id="header">
        <h1>Camera Settings</h1>
        <span (click)="cancel()">X</span>
    </div>

    <div id="body">
        <div class="camera-input">
            <app-input
                [label]="'Camera Name'"
                [type]="'text'"
                [placeholder]="'e.g. front door'"
                [(value)]="camera.name"></app-input>
        </div>

        <div class="camera-input">
            <app-input
                [label]="'Camera URI'"
                [type]="'text'"
                [placeholder]="'e.g. http:127.0.0.1/url'"
                [(value)]="camera.uri"></app-input>
        </div>

        <div class="camera-input"  *ngIf="deviceQueryResult">
            <label>Device</label>
            <select class="app-select" [(ngModel)]="camera.leaf_id">
                <option></option>
                <option
                    *ngFor="let device of deviceQueryResult.data" [value]="device._id">{{device.name}}</option>
            </select>
        </div>

        <div class="camera-input" id="dimensions">
            <app-input
                [label]="'Camera Width'"
                [type]="'number'"
                [placeholder]="'1920'"
                [(value)]="camera.dimensions[0]"></app-input>

            <app-input
                [label]="'Camera Height'"
                [type]="'number'"
                [placeholder]="'1920'"
                [(value)]="camera.dimensions[1]"></app-input>
        </div>

        <div class="camera-input" id="actions">
            <button id="cancel" (click)="cancel()">Cancel</button>
            <button id="save" (click)="save()">Save</button>
        </div>
        
    </div>
</div>