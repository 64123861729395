import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  report: any;
  query: any;
  startDate: FormControl;
  endDate: FormControl;

  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTags: string[] = ['person', 'car', 'backpack'];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(private reportService: ReportService) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allTags.slice()));

    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.startDate = new FormControl(start);

    let end = new Date();
    end.setHours(23, 59, 59, 99);
    this.endDate = new FormControl(end);

    this.query = {
      'start_date': start.getTime()/1000,
      'end_date': end.getTime()/1000,
      'type': ['frame']
    };
  }

  ngOnInit(): void {
    this.getReports();
  }

  getReports() {
    this.reportService.getReports(this.query).subscribe((results) => {
      this.report = results;
    })
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.query.object_type.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.query.object_type.indexOf(tag.toLowerCase());
    if (index >= 0) {
      this.query.object_type.splice(index, 1);
    }
    this.getReports();
  }

  onTypeSelected($event: any) {
    this.query.type = [$event['target']['value']];
    if(this.query['type'][0] == 'frame') {
      delete this.query['object_type'];
    }
    else {
      this.query['object_type'] = ['person'];
    }
    this.getReports();
  }

  onObjectTypeSelected(event: MatAutocompleteSelectedEvent): void {
    this.query.object_type.push(event.option.viewValue.toLowerCase());
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.getReports();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  onDateSelect($event: any, type: string) {
    let date = new Date($event['value']);
    this.query[type] = date.getTime()/1000;
    this.getReports();
  }

}
