<form class="autocomplete-form">
    <mat-form-field class="autocomplete-form-field" appearance="fill">
      <mat-label>Start typing to search cameras</mat-label>
      <input type="text" matInput [formControl]="form" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="onOptionSelected($event.option.value)">
          <mat-option *ngFor="let option of options" [value]="option">
            {{option.name}}
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>