<div id="eventrule-holder">

    <div class="content-section">
        <app-input
            [label]="'Description'"
            [placeholder]="'Rule Description'"
            [(value)]="eventRule.description"></app-input>
    </div>

    <div class="content-section">
        <div id="header">
            <h5>CRITERIA</h5>
            <div class="add">
                <img
                    (click)="addCriteria()"
                    src="../../../../assets/images/icons/circle-add.svg" />
            </div>
        </div>

        <div *ngIf="eventRule">

            <div class="app-form" *ngFor="let criteria of eventRule.matches.criteria; let cindex = index;">
                <app-select
                    [label]="'Object Type'"
                    [options]="objectTypes"
                    [(value)]="criteria.type"></app-select>

                <app-select
                    [label]="'Attribute'"
                    [(value)]="criteria.attribute"
                    [options]="criteriaAttributes"></app-select>

                <app-input
                    *ngIf=" criteria.attribute == 'count' "
                    [label]="'Overcrowding Limit'"
                    [type]="'number'"
                    [placeholder]="'Enter limit'"
                    [(value)]="criteria.meta.limit"></app-input>

                <app-input
                    *ngIf=" criteria.attribute == 'loitering' "
                    [label]="'Duration (seconds)'"
                    [type]="'number'"
                    [placeholder]="'Enter limit'"
                    [(value)]="criteria.meta.limit"></app-input>

                <app-input
                    *ngIf=" criteria.attribute == 'line' "
                    [label]="'Line Crossing Direction'"
                    [type]="'text'"
                    [placeholder]="'Enter line'"
                    [(value)]="criteria.meta.line"></app-input>

                <div class="app-form-field" *ngIf=" criteria.attribute == 'face' ">
                    <mat-label>People</mat-label>
                    <app-people-autocomplete
                        (onChange)="onPersonSelected($event, cindex)"
                        [id]="criteria.meta.face_id"></app-people-autocomplete>
                </div>

                <div class="app-form-field" *ngIf="!isTemplate">
                    <mat-label>Region</mat-label>
                    <app-region-autocomplete
                        (onChange)="onRegionSelected($event, cindex)"
                        [regionId]="criteria.region[0]"
                        [cameraId]="_camera._id"></app-region-autocomplete>
                </div>

                <div>
                    <div id="header">
                        <h5>ACTIONS</h5>
                        <div class="add">
                            <img
                                (click)="addCriteriaAction(cindex)"
                                src="../../../../assets/images/icons/circle-add.svg" />
                        </div>
                    </div>

                    <div id="action-row" *ngFor="let action of criteria.actions; let aindex = index">
                        <div>
                            <mat-label>Action Type</mat-label>
                            <select class="app-select" [(ngModel)]="action.type">
                                <option value=""></option>
                                <option value="alert">Alert</option>
                                <option value="record">Record</option>
                            </select>
                        </div>

                        <div>
                            <mat-label>Groups</mat-label>
                            <app-alertgroup-autocomplete
                                [id]="action.groups[0]"
                                (onChange)="onGroupSelected($event, cindex, aindex)"></app-alertgroup-autocomplete>
                        </div>
                        <div id="remove">
                            <img
                                (click)="removeCriteriaAction(cindex, aindex)"
                                src="../../../../assets/images/icons/trash.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="actions">
        <button id="cancel" (click)="cancel()">Cancel</button>
        <button id="save" (click)="save()">Save</button>
    </div>
</div>