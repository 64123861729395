import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraService } from 'src/app/services/camera/camera.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService, MailGroup } from 'src/app/services/user/user.service';
import { QueryResult } from 'src/app/utils/query-result';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { FormControl, Validators } from '@angular/forms';
import { Shape } from 'src/app/features/roi/roi.model';
import { DeviceService } from 'src/app/services/device/device.service';
import { Camera } from 'src/app/services/camera/camera.model';
import { Device } from 'src/app/services/device/device.models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  _camera: Camera;
  _device: Device;
  region: Region;
  roi: Region;
  listname: string;
  formControl = new FormControl('', [
    Validators.required
  ]);

  regionTypes = [
    { 'label': 'Box', 'value': 'box' },
    { 'label': 'Line', 'value': 'line' }
  ];

  constructor(private router: Router, private cameraService: CameraService, private snackBar: MatSnackBar,
    private userService: UserService, private route: ActivatedRoute, private regionService: RegionService,
    private deviceService: DeviceService, private dialogRef: MatDialogRef<ZonesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this._camera = this.data['camera'];
    if(this.data['region']) {
      this.region = this.data['region'];
      this.roi = this.data['region'];
    }
    else {
      this.region = new Region();
      this.region.type = 'box';
      this.region.camera_id = this._camera._id;
      this.region.leaf_id = this._camera.leaf_id;
    }
  }

  roiChange($event: any) {
    this.region.points = $event;
  }

  onCameraSelected($event: any) {
    this.region.camera_id = $event['_id'];
  }

  onDeviceSelected($event: any) {
    this.region.leaf_id = $event['_id'];
  }

  cancel() {
    this.dialogRef.close();
  }

  remove() {
    this.regionService.deleteRegion(this.region._id).subscribe(() => {
      this.snackBar.open('Region was successfully removed', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
      this.dialogRef.close();
    });
  }

  save() {
    this.regionService.saveRegion(this.region).subscribe(result => {
      this.snackBar.open('Region was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
      this.dialogRef.close();
    }, (error) => {
      this.snackBar.open('Region could not be saved. Please try again later.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
      this.dialogRef.close();
    });
  }

}