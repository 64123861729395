import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CameraService } from 'src/app/services/camera/camera.service';
import { ReportsService, Report } from 'src/app/services/reports/reports.service';
import { Seen, SeenService } from 'src/app/services/seen/seen.service';
import { Camera } from 'src/app/services/camera/camera.model';
import { QueryResult } from 'src/app/utils/query-result';
import { EventModalComponent } from 'src/app/modals/event-modal/event-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  alerts: any[];
  cameras: QueryResult<Camera>;
  selectedFeed: string;

  selectedEvent: Report;
  totalEvents: number;
  events: Report[];

  seenList: Seen[];
  query: any;

  timer: any;

  constructor(private profileService: ProfileService, private cameraService: CameraService,
    private reportsService: ReportsService, private seenService: SeenService,
    private dialog: MatDialog, private socket: WebsocketService,
    public config: ConfigService) {
      this.socket.messages.subscribe(msg => {
        console.log("Response from websocket: " + JSON.stringify(msg));
      });
    }

  ngOnInit(): void {
    let start = new Date();
    start.setHours(0, 0, 0, 0);

    let end = new Date();
    end.setHours(23, 59, 59, 99);

    this.query = {
      'offset': 0,
      'size': 10,
      'start_date': start.getTime()/1000,
      'end_date': end.getTime()/1000,
    };

    this.selectedFeed = 'all';
    
    this.getReports();
    this.getCameras();

    /*this.timer = setInterval(() => {
      this.getReports();
      this.getCameras();
    }, 10000);*/
  }

  getSeen() {
    this.seenService.getSeen().subscribe((result) => {
      this.seenList = result;
    });
  }

  getCameras() {
    this.cameraService.getCameras().subscribe((cameras) => {
      if(cameras) {
        this.cameras = cameras;
      }
    });
  }

  getReports() {
    this.reportsService.getReports(this.query).subscribe(events => {
      this.totalEvents = events['sount'];
      this.events = events['events'];
    })
  }

  feedChange(feedId: string) { }

  feedRotate(forward: boolean) { }

  onEventSelected(index: number) {
    this.selectedEvent = this.events[index];
    const dialogRef = this.dialog.open(EventModalComponent, {
      panelClass: 'event-modal',
      backdropClass: 'people-create-backdrop',
      data: {
        event: this.selectedEvent,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

}
