<div class="row picker">
    <div class="col-5">
        <h5>Users</h5>
        <ul>
            <li *ngFor="let a of available; let i = index" (click)="add(i)">{{getValue(a)}}</li>
        </ul>
    </div>
    <div class="col-1 arrow">
        <img src="assets/images/chevron-right-512.png" />
    </div>
    <div class="col-6">
        <h5>Mailing List</h5>
        <ul>
            <li *ngFor="let s of selected; let i = index" (click)="remove(i)">{{getValue(s)}}</li>
        </ul>
    </div>
</div>