import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss']
})
export class AppSelectComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  options: any[]

  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onSelected: EventEmitter<string> = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void { }

  onChange() {
    this.valueChange.emit(this.value);
    if(this.onSelected) {
      this.onSelected.emit(this.value);
    }
  }

}
