export class Camera {
    _id: string;
    acc_id: string;
    name: string;
    leaf_id: string;
    uri: string;
    thumbnail?: string;
    dimensions: number[];
    enabled: boolean;

    constructor() {
        this.enabled = true;
    }
}