<div *ngIf=" event && event.record_name!='' ">
    <video
        #video
        id="videoPlayer" width="100%" height="100%" controls muted="muted" autoplay *ngIf="isVideo">
        <source [src]="eventVideoSource" type="video/mp4" />
    </video>
    <img id="event-image" src="{{eventImage}}" *ngIf="!isVideo" />
</div>

<div id="event-info">
    <mat-chip-list class="mat-chip-list-stacked" aria-label="Color selection">
        <!--<mat-chip selected [color]="'warn'" *ngIf="event.record.status!=1">
            Recording is still being uploaded
        </mat-chip>

        <mat-chip selected [color]="'primary'" *ngIf="event.record.status==1">
            Recording available
        </mat-chip>-->
    </mat-chip-list>

    <div class="event-info-item" *ngIf="eventCriteria">
        <span>Description</span>
        <app-event-description [event]="event"></app-event-description>
    </div>
    <div class="event-info-item" *ngIf="!eventCriteria">
        <span>Description</span>
        <p>{{ event.meta.count }} detected</p>
    </div>

    <div class="event-info-item">
        <span>Date</span>
        <p>{{ event.timestamp | date:'medium' }}</p>
    </div>

    <div class="event-info-item">
        <span>Camera</span>
        <p>{{ event.camera_name }}</p>
    </div>

    <div class="event-info-item">
        <span>Faces</span>
        <p *ngIf="!faces || faces.length==0">No faces found</p>
        <div class="face-images" *ngIf="faces && faces.length>0">
            <div class="face-image" *ngFor="let face of faces">
                <img src="{{face.images[0]}}" />
            </div>
        </div>
    </div>
</div>