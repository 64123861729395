<div id="people-create-holder">
    <div id="header">
        <h1>Add new person</h1>
        <span (click)="cancel()">X</span>
    </div>

    <div id="body">
        <app-input
            [label]="'Person\'s Name'"
            [type]="'text'"
            [placeholder]="'Unknown Person'"
            [(value)]="name"></app-input>

        <div id="upload-area">
            <label>Person’s Image(s)</label>
            <div id="big-upload" *ngIf="selectedImages.length==0">
                <p><b>Click to upload</b> or drag and drop</p>
                <p>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                <input type="file" (change)="onSelectFile($event)"/>
            </div>

            <div id="selected-images" *ngIf="selectedImages.length>0">
                <span class="selected-img" *ngFor="let img of selectedImages">
                    <img [src]="img" />
                </span>

                <span id="add-image">
                    <input type="file" (change)="onSelectFile($event)"/>
                    <img src="../../../../../assets/images/icons/circle-add.svg" />
                </span>
            </div>
            <div id="actions">
                <button id="cancel" (click)="cancel()">Cancel</button>
                <progress-button
                    id="save"
                    [text]="'Save'"
                    [loading]="isSaving"
                    (click)="save()"></progress-button>
            </div>
        </div>
    </div>
</div>
