<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Devices</h1>
            <h2>Create and manage your devies.</h2>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <div class="highlight-table container" *ngIf="deviceQueryResult">
                <div class="row header">
                    <div class="col-2">Name</div>
                    <div class="col-2">Device Status</div>
                    <div class="col-2">AI Process Status</div>
                    <div class="col-2">Average FPS</div>
                    <div class="col-2">No. of Cameras</div>
                    <div class="col-2">Action</div>
                </div>
                <div  id="table-data">
                    <div *ngFor="let device of deviceQueryResult.data; let i = index;">
                        <div class="row highlighted">
                            <div class="col-2">{{device.name}}</div>
                            <div class="col-2">
                                <img
                                    *ngIf="deviceStatuses[device._id]"
                                    src="../../../assets/images/badges/camera-online.svg" />
                            </div>
                            <div class="col-2">
                                <img
                                    *ngIf="deviceStatuses[device._id] && deviceStatuses[device._id]['pipeline']=='Active'"
                                    src="../../../assets/images/badges/camera-online.svg" />
                            </div>
                            <div class="col-2">
                                <span *ngIf="deviceStatuses && deviceStatuses[device._id]">
                                    {{deviceStatuses[device._id]["fps"]}}</span>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-2 actions">
                                <button
                                    class="action-item"
                                    [routerLink]="['/settings/devices', device._id]">
                                    <img src="../../../assets/images/icons/edit.svg" />
                                    <span>Edit</span>
                                </button>

                                <button
                                    class="action-item"
                                    (click)="deleteDevice(i)">
                                    <img src="../../../assets/images/icons/trash.svg" />
                                    <span>Delete</span>
                                </button>

                                <app-select
                                    [label]="'Commands'"
                                    [options]="commandOptions"
                                    (valueChange)="onCommandSelected($event, i)"></app-select>
                            </div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>
</div>