import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss']
})
export class AppInputComponent implements OnInit {

  @Input()
  label: string;
  
  @Input()
  placeholder: string
  
  @Input()
  type: string
  
  @Input()
  value: any;
  
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

  onChange() {
    this.valueChange.emit(this.value);
  }

}
