import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { Device } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';
import { EventRule, EventRuleCriteria, EventRuleCriteriaAction } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-event-rule-update',
  templateUrl: './event-rule-update.component.html',
  styleUrls: ['./event-rule-update.component.scss']
})
export class EventRuleUpdateComponent implements OnInit {

  eventRule: EventRule;
  _camera: Camera;
  _device: Device;
  isTemplate: boolean = false;

  matchTypes = [{'label': 'Any', 'value': 'ANY'}];
  objectTypes = [
    {'label': 'Person', 'value': 'person'},
    {'label': 'Car', 'value': 'car'},
    {'label': 'Motorbike', 'value': 'motorbike'},
    {'label': 'Truck', 'value': 'truck'},
    {'label': 'Backpack', 'value': 'backpack'},
    {'label': 'Chair', 'value': 'chair'},
    {'label': 'Tv/Monitor', 'value': 'tvmonitor'},
    {'label': 'Cell Phone', 'value': 'cell phone'}
  ];
  criteriaAttributes = [
    {'label': 'Out of Zone', 'value': 'outzone'},
    {'label': 'Line Crossing', 'value': 'line'},
    {'label': 'Occupancy', 'value': 'count'},
    {'label': 'Facial Recognition', 'value': 'face'},
    {'label': 'Loitering', 'value': 'loitering'}
  ];

  deviceQueryResult: QueryResult<Device>
  constructor(private route: ActivatedRoute, private eventRulesService: EventRulesService,
    private snackBar: MatSnackBar, private dialogRef: MatDialogRef<EventRuleUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data['template']) {
        this.isTemplate = true;
      }
  }

  ngOnInit(): void {
    this._camera = this.data['camera'];
    if(this.data['rule']) {
      this.eventRule = this.data['rule'];
    }
    else {
      this.eventRule = new EventRule();
      this.eventRule.template = this.isTemplate;

      if(!this.isTemplate) {
        this.eventRule.camera_id = this._camera._id;
        this.eventRule.leaf_id = this._camera.leaf_id;
      }
      this.addCriteria();
    }
  }

  onCameraSelected($event: any) {
    this.eventRule.camera_id = $event['_id'];
  }

  onDeviceSelected($event: any) {
    this.eventRule.leaf_id = $event['_id'];
  }

  onRegionSelected($event: any, index: number) {
    this.eventRule.matches.criteria[index].region = [$event['_id']];
  }

  onPersonSelected($event: any, index: number) {
    this.eventRule.matches.criteria[index].meta['face_id'] = $event['face_ids'][0];
  }

  addCriteriaAction(index: number) {
    if(!this.eventRule.matches.criteria[index].actions) {
      this.eventRule.matches.criteria[index].actions = [];
    }
    this.eventRule.matches.criteria[index].actions.push(new EventRuleCriteriaAction());
  }

  onGroupSelected($event: any, cindex: number, aindex: number) {
    this.eventRule.matches.criteria[cindex].actions[aindex].groups = [$event['_id']];
  }

  addCriteria() {
    let criteria = new EventRuleCriteria();
    let action = new EventRuleCriteriaAction();
    action.type = 'record';
    criteria.actions = [action];
    this.eventRule.matches.criteria.push(criteria);
  }

  removeCriteriaAction(cindex: number, aindex: number) {
    this.eventRule.matches.criteria[cindex].actions.splice(aindex, 1);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.eventRulesService.saveEventRule(this.eventRule).subscribe(() => {
      this.snackBar.open('The event rules was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
      this.dialogRef.close(true);
    },
    (err) => {
      this.snackBar.open('The event rule could not be saved. Please try again.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
      this.dialogRef.close();
    });
  }

}
