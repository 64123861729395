import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Region } from 'src/app/services/region/region.model';
import { Shape } from './roi.model';

@Component({
  selector: 'app-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.scss']
})
export class RoiComponent implements OnInit {

  roiWidth: number = 500;
  roiHeight: number = 400;


  @Output()
  change: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input()
  cameraWidth: number;

  @Input()
  cameraHeight: number;

  @Input()
  thumbnail: string;

  @Input()
  set roi(region: Region) {
    if(region) {
      
      // these Input() need to be declared before roi in the selector
      let widthScale = this.cameraWidth / this.roiWidth;
      let heightScale = this.cameraHeight / this.roiHeight;

      if(region.type == 'box') {
        let width = (region.points[4] - region.points[0]) / widthScale;
        let height = (region.points[5] - region.points[1]) / heightScale;
        let roi = new Shape(width, height);
        roi.x = region.points[0];
        roi.y = region.points[1];
        this.shapesToDraw = [roi];
      }
      else if(region.type == 'line') {
        let width = region.points[6] - region.points[4];
        let height = region.points[7] - region.points[5];
        let roi = new Shape(width, height);
        roi.x = region.points[4];
        roi.y = region.points[5] / heightScale;
        this.shapesToDraw.push(roi);

        width = region.points[2] - region.points[0];
        height = region.points[3] - region.points[1];
        roi = new Shape(width, height);
        roi.x = region.points[0];
        roi.y = region.points[1] / heightScale;
        this.shapesToDraw.push(roi);
      }
    }
  }

  @Input()
  type: string;

  shapesToDraw: Shape[] = [];
  createdShape: Shape;

  constructor() { }

  ngOnInit(): void { }
  
  ngAfterViewInit(): void { }

  flipDirectionLine() {
    if(this.shapesToDraw) {
      let nshape = new Shape(this.shapesToDraw[1].width * -1, this.shapesToDraw[1].height * -1);
      nshape.x = this.shapesToDraw[1].x;
      nshape.y = this.shapesToDraw[1].y;
      this.shapesToDraw[1] = nshape;
      this.emitPoints();
    }
  }

  startDrawing(evt: MouseEvent) {
    this.createdShape = {
      x: evt.offsetX,
      y: evt.offsetY,
      width: 0,
      height: 0
    };
    this.shapesToDraw = [this.createdShape];
  }


  keepDrawing(evt: MouseEvent) {
    if (this.createdShape) {
      this.createdShape.width = evt.offsetX - this.createdShape.x;
      this.createdShape.height = evt.offsetY - this.createdShape.y;
    }
  }

  stopDrawing() {
    if(this.type == 'line') {
      let dline = this.calculateDirectionLine();
      this.shapesToDraw.push(dline);
    }
    this.emitPoints();
    this.createdShape = null;
  }

  emitPoints() {
    let points = [];
    
    let widthScale = this.cameraWidth / this.roiWidth;
    let heightScale = this.cameraHeight / this.roiHeight;
    if(this.type == 'box') {

      points.push(this.createdShape.x);
      points.push(this.createdShape.y);
      
      points.push(this.createdShape.x + (this.createdShape.width * widthScale));
      points.push(this.createdShape.y);
      
      points.push(this.createdShape.x + (this.createdShape.width * widthScale));
      points.push(this.createdShape.y + (this.createdShape.height * heightScale));
      
      points.push(this.createdShape.x);
      points.push(this.createdShape.y + (this.createdShape.height * heightScale));
    }
    else if(this.type == 'line') {
      let a = [...this.shapesToDraw].reverse();
      a.forEach((shape) => {
        points.push(shape.x);
        points.push(shape.y * heightScale);
        points.push(shape.x + shape.width);
        points.push((shape.y * heightScale) + shape.height);
      });
    }

    points = points.map((point) => {
      return Math.ceil(point);
    });

    this.change.emit(points);
  }

  calculateDirectionLine(): Shape {
    let x1 = this.createdShape.x;
    let y1 = this.createdShape.y;
    let x2 = x1 + this.createdShape.width;
    let y2 = y1 + this.createdShape.height;

    let slope = (y2-y1) / (x2-x1);
    
    let x3 = (x1 + x2)/2;
    let y3 = (y1 + y2)/2
    
    let slope2 = -1 / slope;
    let yintercept2 = y3 - (slope2*x3);

    let w = 50;

    let x4 = (w + y3 - yintercept2) / slope2;
    let y4 = (slope2* (x4)) + yintercept2;

    let width2 = x4-x3;
    let height2 = y4-y3;

    return {
      x: Math.round(x3),
      y: Math.round(y3),
      width: Math.round(width2),
      height: Math.round(height2)
    };
  }

}
