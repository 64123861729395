<button *ngIf=" type=='line' " (click)="flipDirectionLine()">Reverse Direction</button><br/>
<svg
    class="shapeCanvas"
    [style]=" 'background-image: url(' + thumbnail + '); background-size: 100% 100%;' "
  (mousedown)="startDrawing($event)" 
  (mouseup)="stopDrawing()"
  (mousemove)="keepDrawing($event)">
    <ng-container *ngFor="let shape of shapesToDraw">
        <rect class="shape"
            *ngIf="type=='box'"
            [style.stroke-width]="2"
            [attr.x]="shape.x"
            [attr.y]="shape.y"
            [attr.width]="shape.width"
            [attr.height]="shape.height"></rect>
        
        <line class="shape"
            *ngIf="type=='line'"
            [style.stroke-width]="2" 
            [attr.x1]="shape.x" 
            [attr.y1]="shape.y" 
            [attr.x2]="shape.x*1+shape.width*1"       
            [attr.y2]="shape.y*1+shape.height*1"></line>
    </ng-container>
</svg>