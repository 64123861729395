import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { People } from 'src/app/services/mesh/mesh.models';
import { MeshService } from 'src/app/services/mesh/mesh.service';
import { PeopleCreateComponent } from '../create/people-create/people-create.component';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/modals/confirm-dialog/confirm.model';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  totalPages: number;
  pageSize: number;
  currentPage: number;
  query: any = {};
  
  people: QueryResult<People>

  constructor(private meshService: MeshService, private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.pageSize = 10;
    this.currentPage = 1;
    this.query = {
      limit: this.pageSize,
      offset: 0
    };
    this.getPeople();
  }

  getPeople() {
    this.query['offset'] = ((this.currentPage - 1)*this.pageSize);
    this.meshService.getPeople(this.query).subscribe((result) => {
      this.people = result;
      this.totalPages = Math.ceil(result['total']/this.pageSize);
    });
  }

  createPerson() {
    const dialogRef = this.dialog.open(PeopleCreateComponent, {
      panelClass: 'people-create-modal',
      backdropClass: 'people-create-backdrop'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getPeople();
    });
  }

  deletePerson(index: number) {
    const message = `Are you sure you want to do this?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.meshService.deletePerson(this.people.data[index]._id).subscribe(() => {
          this.snackBar.open('Person was successfully saved', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
          this.people.data.splice(index, 1);
        })
      }
    });
  }

  onPaginate($event: any) {
    this.currentPage = $event;
    this.getPeople();
  }

}
