import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

const unauthenticatedPaths = [
  '',
  'signup',
  'login'
];

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    let isAuthenticated: boolean = this.authService.isAuthenticated();
    let params = route.queryParams;
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    // get current path
    let path = route.routeConfig?.path || '';
    
    if(isAuthenticated && path=='') {
      this.router.navigate(["/live"]);
      return false;
    }
    else if(!isAuthenticated && (unauthenticatedPaths.indexOf(path) < 0) ) {
      this.authService.$isLoggedIn.next(false);
      this.router.navigate( ['/'], { queryParams: { redirect: path, params: queryString } } );
      return false;
    }
    return true;
  }

}
