import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-facial-analysis',
  templateUrl: './facial-analysis.component.html',
  styleUrls: ['./facial-analysis.component.scss']
})
export class FacialAnalysisComponent implements OnInit {

  events: any[];
  url: SafeResourceUrl;
  cameras: QueryResult<Camera>;
  cameraOptions: any[];

  constructor(private socket: WebsocketService, private cameraService: CameraService,
    private sanitizer: DomSanitizer) {
    this.events = [];
    this.socket.messages.subscribe(msg => {
      try {
        msg['data']['images'] = msg['data']['images'].map((img: string) => {
          return 'http://174.129.145.235:8080/mesh/v1/media/' + img;
        });

        if(msg['data']['base_images']) {
          msg['data']['base_images'] = msg['data']['base_images'].map((img: string) => {
            return 'http://174.129.145.235:8080/mesh/v1/media/' + img;
          });
        }
        console.log("Response from websocket: " + JSON.stringify(msg));
        this.events.unshift(msg);

        if(this.events.length > 10) {
          this.events.pop();
        }
      }
      catch(err) {
        console.log(err);
      }
    });
  }

  ngOnInit(): void {
    this.cameraOptions = [];
    this.getCameras();
  }

  getCameras() {
    this.cameraService.getCameras().subscribe((cameras) => {
      if(cameras) {
        this.cameras = cameras;

        if(cameras.total>0) {
          this.selectCamera(0);
        }

        this.cameraOptions = this.cameras.data.map((camera) => {
          return { 'value': camera._id, 'label': camera.name };
        })
      }
    });
  }

  selectCamera(index: number) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'http://18.206.153.191:8888/' + this.cameras.data[index]._id);
  }

  onCameraChange($event) {
    let f = this.cameras.data.filter(camera => {
      return camera._id == $event;
    });
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'http://18.206.153.191:8888/' + f[0]._id);

  }

}
