import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Auth } from './auth.model';
import { EnvService } from '../env/env.service';

const STORAGE_KEY = 'AUTH';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $isLoggedIn: BehaviorSubject<Boolean>;
  constructor(private api: ApiService, private storage: LocalStorageService, private envService: EnvService) {
    this.$isLoggedIn = new BehaviorSubject<Boolean>(this.isAuthenticated());
  }

  login(email: string, password: string) {
    let credentials = {
      email: email,
      pwd: password
    };

    const loginHandler = () => (source: Observable<string>) =>
      new Observable(observer => {
        return source.subscribe((result: any) => {
          let token: Auth = new Auth(result);
          this.storage.set(STORAGE_KEY, token);
          this.$isLoggedIn.next(true);
          observer.next();
        },
          (error) => { observer.error(error); });
      });
    return this.api.post(this.envService.PLATFORM + '/login', credentials).pipe(loginHandler());
  }

  logout() {
    const logoutHandler = () => (source: Observable<string>) =>
      new Observable(observer => {
        return source.subscribe((result) => {
          this.storage.remove(STORAGE_KEY);
          this.$isLoggedIn.next(false);
          observer.next();
        },
        (error) => {
          this.storage.remove(STORAGE_KEY);
          this.$isLoggedIn.next(false);
          observer.error(error);
        });
      });
    return this.api.post(this.envService.PLATFORM + '/logout', {}).pipe(logoutHandler());
  }

  getAuthToken() {
    return this.storage.get(STORAGE_KEY) as Auth;
  }

  isAuthenticated(): boolean {
    let token: Auth = this.getAuthToken() as Auth;
    return token && (token.session_expiry > Date.now()/1000);
  }

  saveAuthToken(token: any) {
    this.storage.set(STORAGE_KEY, token);
  }
}
