import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  isMenuActive: boolean;
  isDarkMode: boolean;

  constructor() {
    this.isMenuActive = false;
    this.isDarkMode = true;
  }

  setIsMenuActive(isActive: boolean) {
    this.isMenuActive = isActive;
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
  }
}
