import { Component, OnInit } from '@angular/core';
import { Profile, ProfileService } from 'src/app/services/profile/profile.service';
import { Router } from '@angular/router';
import { LookoutService } from 'src/app/services/lookout/lookout.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lookout',
  templateUrl: './lookout.component.html',
  styleUrls: ['./lookout.component.scss']
})
export class LookoutComponent implements OnInit {

  selections: any;
  profiles: Profile[];
  constructor(private profileService: ProfileService, private router: Router,
    private lookoutService: LookoutService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.selections = {};
    this.profileService.getProfiles().subscribe((result) => {
      this.profiles = result;
    });
  }

  onChange($event: any, id: string, name: string) {
    if($event.currentTarget.checked) {
      this.selections[id] = name;
    } else {
      delete this.selections[id];
    }
  }

  save() {
    let lookouts: string[] = Object.values(this.selections);
    if(lookouts.length > 0) {
      this.lookoutService.addLookout(lookouts[0]).subscribe(result => {
        this.snackBar.open('Lookout record was successfully added', 'dismiss', {
          duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
        });
      }, error => {
        this.snackBar.open('Lookout record could not be added. Please try again later.', 'dismiss', {
          duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
        });
      });
    }
  }

}
