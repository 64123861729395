export class Shape {
    x: number;
    y: number;
    width: number;
    height: number;

    constructor(width: number, height: number) {
        this.x = 0;
        this.y = 0;
        this.width = width;
        this.height = height;
    }
}