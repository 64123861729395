import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryResult } from "src/app/utils/query-result";
import { Region } from "./region.model";
import { RegionService } from "./region.service";

@Injectable()
export class MockRegionService extends RegionService {

    getRegions(query: any = {}) {
        return new Observable<QueryResult<Region>>(observer => {
            let regionQueryResult = new QueryResult<Region>();
            regionQueryResult.data = [];
            regionQueryResult.data.push(
                { _id: '1', name: 'Region 1', leaf_id: '01', camera_id: '01', type: 'box', points: [1024, 768]},
                { _id: '2', name: 'Region 2', leaf_id: '01', camera_id: '02', type: 'box', points: [1024, 768]},
            );
            regionQueryResult.total = regionQueryResult.data.length;
            observer.next(regionQueryResult);
        });
    }

    saveRegion(region: Region) {
        return new Observable<Region>(observer => {
            observer.next(region);
        });
    }
}