import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-analyzer',
  templateUrl: './analyzer.component.html',
  styleUrls: ['./analyzer.component.scss']
})
export class AnalyzerComponent implements OnInit {

  file: any;
  imageResult: string;
  profiles: any[];

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
  }

  analyze() {
    this.imageResult = undefined;

    let fileList: FileList = this.file;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      this.profileService.analyze(formData).subscribe(result => {
        this.imageResult = result['image'];
        this.profiles = Object.values(result['persons']);
        console.log(this.profiles);
      })
    }
  }

  fileChange(event) {
    this.file = event.target.files;
  }

}
