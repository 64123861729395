<div class="page livefeed">
    <div class="page-header">
        <h5>Seen</h5>
        <span>All person that have been seen</span>
    </div>
    <div class="row">
        <div class="col-12" id="history">
            <div class="row header">
                <div class="col-2"></div>
                <div class="col-4">Timestamp</div>
                <div class="col-2">Cam</div>
                <div class="col-2">Action</div>
            </div>
            <div *ngFor="let profile of seenList">
                <div class="row highlighted">
                    <div class="col-2">
                        <!--<img src="https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg" />-->
                        <img src="http://13.82.102.247:5010/get_image?file={{profile.image}}" />
                    </div>
                    <div class="col-4">Dec 2, 2017 2:39:58 AM</div>
                    <div class="col-2">Cam 1</div>
                    <div class="col-2">
                        <span routerLink="/seen/search">
                            <mat-icon class="example-icon" routerLink="/seen/search/{{profile.feature}}">search</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="row spacer">
                </div>
            </div>
        </div>
    </div>
</div>