import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  public PLATFORM = '';
  public PROFILE = '';
  public MIDDLE = 'http://13.82.102.247:5018';
  public REPORT = '';
  public STATIC = 'http://13.82.102.247:5001/images/';
  public ACCOUNT = 'http://13.82.102.247:5016';
  public GOTHAM = '';
  public NOTIFICATION = '';
  public MESH = '';
  public WEBSOCKET = '';
  public FILESERVER = '';
  
  public mock = false;

  constructor() { }
}
