export class AlertGroup {
    _id?: string;
    name: string;
    channel: string;
    notification_interval: number;
    users?: string[];
    emails?: string[];
    account_id: string;

    constructor() {
        this.users = [];
        this.emails = [];
    }
}