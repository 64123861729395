import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { ApiService } from '../api/api.service';
import { AlertGroup } from './alert.model';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getAlertGroups(query: any = {}) {
    const alertGroupPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<AlertGroup>>(observer => {
        source.subscribe((result) => {
          let queryResult = new QueryResult<AlertGroup>();
          queryResult.data = result['groups'];
          queryResult.total = result['count'];
          observer.next(queryResult);
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.NOTIFICATION + '/v1/alert/group/query', query).pipe(alertGroupPipe())
  }

  saveAlertGroup(alertGroup: AlertGroup) {
    const alertGroupPipe = () => (source: Observable<any>) =>
      new Observable<AlertGroup>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error(error);
        });
      });

    let url = this.envService.NOTIFICATION + `/v1/alert/group`;
    if(alertGroup._id) {
      url += `/${alertGroup._id}`;
      return this.api.put(url, alertGroup).pipe(alertGroupPipe());  
    }
    return this.api.post(url, alertGroup).pipe(alertGroupPipe());
  }
}
