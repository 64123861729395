<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Camera Select</h1>
            <h2></h2>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <div class="highlight-table container" *ngIf="cameraQueryResult">
                <div class="row header">
                    <div class="col-3"></div>
                    <div class="col-3">Name</div>
                    <div class="col-3">Status</div>
                    <div class="col-3">Size</div>
                </div>
                <div  id="table-data">
                    <div *ngFor="let camera of cameraQueryResult.data; let i = index;">
                        <div class="row highlighted">
                            <div class="col-3">
                                <mat-checkbox
                                    color="primary"
                                    type="checkbox"
                                    (change)="onCameraSelected($event, camera._id)"
                                    [checked]="isCameraSelected(camera._id)"></mat-checkbox>
                            </div>
                            <div class="col-3">{{camera.name}}</div>
                            <div class="col-3">
                                <img src="../../../assets/images/badges/camera-online.svg" />
                            </div>
                            <div class="col-3">{{camera.dimensions[0]}} x {{camera.dimensions[1]}}</div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>

    <div id="actions">
        <button id="cancel" (click)="cancel()">Cancel</button>
        <button id="save" (click)="select()">Select</button>
    </div>
</div>