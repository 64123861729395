<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Edit Person</h1>
            <h2></h2>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="save()">Save Changes</button>
        </div>
    </div>

    <div id="page-content">
        <div id="header" *ngIf="person">
            <div id="basic-info">
                <div id="main-image">
                    <img src="{{person.images[0]}}" />
                </div>
                <div id="person-info">
                    <label>Person's Name</label>
                    <input type="text" [(ngModel)]="person.name" />
                    <span>Profile Created: {{person.createdAt | date:'medium'}}</span>
                </div>
            </div>
            <div id="person-images">
                <div id="images-header">
                    <h5>Additional Images</h5>
                    <a href="#">See All Images</a>
                </div>

                <div id="images-holder">
                    <div>
                        <div class="person-image" *ngFor="let img of person.images">
                            <div><img src="{{img}}" /></div>
                            <span>{{person.name}}</span>
                        </div>
                    </div>
                    <img src="../../../../../assets/images/icons/circle-add.svg" />
                </div>
            </div>
        </div>

        <div class="app-table">
            <div class="highlight-table container">
                <div class="row header">
                    <div class="col-4">Date Seen</div>
                    <div class="col-2">Camera</div>
                    <div class="col-2">Image(s)</div>
                    <div class="col-2">Match Probability</div>
                    <div class="col-2">Actions</div>
                </div>
                <div id="table-data">
                    <div class="row highlighted" *ngFor="let event of personHistory.data; let i = index">
                        <div class="col-4">{{ event.timestamp | date:'medium' }}</div>
                        <div class="col-2">{{ event.camera_name }}</div>
                        <div class="col-2"></div>
                        <div class="col-2">{{ ((event.meta.person_prob/2)*100).toFixed(2) }}%</div>
                        <div class="col-2 actions">
                            <img
                                class="action-item"
                                (click)="onEventSelected(i)"
                                src="../../../assets/images/view-clip.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>