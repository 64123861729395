import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventRule } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { getAttributeLabel } from 'src/app/utils/common';
import { QueryResult } from 'src/app/utils/query-result';
import { EventRuleUpdateComponent } from '../../camera/event-rule-update/event-rule-update.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CameraTableComponent } from 'src/app/modals/camera-table/camera-table.component';
import { Camera } from 'src/app/services/camera/camera.model';

@Component({
  selector: 'app-list-rule-templates',
  templateUrl: './list-rule-templates.component.html',
  styleUrls: ['./list-rule-templates.component.scss']
})
export class ListRuleTemplatesComponent implements OnInit {

  totalPages: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  rulesQueryResult: QueryResult<EventRule>;
  eventRulesAttributes: any = {};
  constructor(private eventRulesService: EventRulesService,
    private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getRuleTemplates();
  }
  
  getRuleTemplates() {
    this.eventRulesService.getEventRules({'template': true}).subscribe((rules) => {
      this.rulesQueryResult = rules;
      this.totalPages = Math.ceil(rules.total/this.pageSize)
      this.generateEventRulesDisplay();
    });
  }

  generateEventRulesDisplay() {
    let resolveLabels = (params: any[]) => {
      let attributes = {
        'attributes': params.map((param: any) => {
          return getAttributeLabel(param['attributes']);
        }),
        'types': params.map((param: any) => { return param['types']; }),
        'actions': params.map((param: any) => { return param['actions'].join(','); }).join(',')
      };
      return attributes;
    };

    this.rulesQueryResult.data.forEach((rule) => {
      let params = rule.matches.criteria.map((criteria) => {
        return {
          'attributes': criteria.attribute,
          'types': criteria.type,
          'actions': criteria.actions.map((action) => { return action.type; })
        };
      });
      this.eventRulesAttributes[rule._id] = resolveLabels(params);
    })
  }

  createEventRule(index?: number) {
    let data = {
      template: true
    };

    if(index!=undefined) {
      data['rule'] = this.rulesQueryResult.data[index]
    }

    const dialogRef = this.dialog.open(EventRuleUpdateComponent, {
      panelClass: 'people-create-modal',
      backdropClass: 'people-create-backdrop',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getRuleTemplates();
      }
    });
  }

  deleteEventRule(i: number) {
    this.eventRulesService.deleteEventRule(this.rulesQueryResult.data[i]._id).subscribe(() => {
      this.rulesQueryResult.data.splice(i, 1);
      this.snackBar.open('Event rule was successfully removed', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

  onPaginate($event: any) {
    this.currentPage = $event;
  }

  applyEventRule(index: number) {
    const dialogRef = this.dialog.open(CameraTableComponent, {
      panelClass: 'default-modal',
      backdropClass: 'default-modal-backdrop'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result['cameras'].length>0) {
        let ids = result['cameras'].map((camera: Camera) => {
          return camera._id;
        });
        this.eventRulesService.applyEventRule(this.rulesQueryResult.data[index]._id, ids).subscribe(() => {
          this.snackBar.open('Event rule was successfully applied to cameras', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    });
  }

}
