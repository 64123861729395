<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Settings</h1>
            <h2>Manage account and preference settings</h2>
        </div>
    </div>

    <div id="page-content">
        <div class="row">
            <div class="col-4">
                <div class="setting-item" routerLink="/alerts">
                    <mat-icon class="example-icon">notification_important</mat-icon>
                    <span>Alerts</span>
                </div>
            </div>
            <div class="col-4" routerLink="/users">
                <div class="setting-item">
                    <mat-icon class="example-icon">supervised_user_circle</mat-icon>
                    <span>Users</span>
                </div>
            </div>
        </div>
    </div>
</div>