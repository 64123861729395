import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { Device } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';
import { EventRule } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { QueryResult } from 'src/app/utils/query-result';
import { ZonesComponent } from '../region-update/zones.component';
import { EventRuleUpdateComponent } from '../event-rule-update/event-rule-update.component';
import { getAttributeLabel } from 'src/app/utils/common';

@Component({
  selector: 'app-camera-view',
  templateUrl: './camera-view.component.html',
  styleUrls: ['./camera-view.component.scss']
})
export class CameraViewComponent implements OnInit {

  camera: Camera;
  deviceQueryResult: QueryResult<Device>;
  regionQueryResult: QueryResult<Region>;
  rulesQueryResult: QueryResult<EventRule>;
  eventRulesAttributes: any = {};

  listname: string;
  list: string[];
  listType: string;
  panelOpenState = false;
  roi: number[];
  personLimit: number;
  dwellTime: number;

  constructor(private route: ActivatedRoute, private cameraService: CameraService,
    private snackBar: MatSnackBar, private router: Router,
    private deviceService: DeviceService, private regionService: RegionService,
    private eventRulesService: EventRulesService, private dialog: MatDialog) {
    this.route.paramMap.subscribe(params => {
      if (params['params']['id']) {
        this.cameraService.getCameras({ '_id': params['params']['id']}).subscribe((results: QueryResult<Camera>) => {
          if(results.total) {
            this.camera = results.data[0];
            this.getRegions();
            this.getEventRules();
          }
        });
      }
      else {
        this.camera = new Camera();
        if (!this.camera.dimensions) {
          this.camera.dimensions = [0,0];
        }
      }
    });
  }

  ngOnInit(): void {
    this.deviceService.getDevices().subscribe((deviceQueryResult) => {
      this.deviceQueryResult = deviceQueryResult;
    });
  }

  getRegions() {
    this.regionService.getRegions({'camera_id': this.camera._id}).subscribe((results: QueryResult<Region>) => {
      this.regionQueryResult = results;
    });
  }

  getEventRules() {
    this.eventRulesService.getEventRules({'camera_id': this.camera._id}).subscribe((results) => {
      this.rulesQueryResult = results;
      this.generateEventRulesDisplay();
    });
  }

  generateEventRulesDisplay() {
    let resolveLabels = (params: any[]) => {
      let attributes = {
        'attributes': params.map((param: any) => {
          return getAttributeLabel(param['attributes']);
        }),
        'types': params.map((param: any) => { return param['types']; }),
        'actions': params.map((param: any) => { return param['actions'].join(','); }).join(',')
      };
      return attributes;
    };

    this.rulesQueryResult.data.forEach((rule) => {
      let params = rule.matches.criteria.map((criteria) => {
        return {
          'attributes': criteria.attribute,
          'types': criteria.type,
          'actions': criteria.actions.map((action) => { return action.type; })
        };
      });
      this.eventRulesAttributes[rule._id] = resolveLabels(params);
    })
  }

  createEventRule(index?: number) {
    let data = {
      camera: this.camera
    };

    if(index!=undefined) {
      data['rule'] = this.rulesQueryResult.data[index]
    }

    const dialogRef = this.dialog.open(EventRuleUpdateComponent, {
      panelClass: 'people-create-modal',
      backdropClass: 'people-create-backdrop',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getEventRules();
      }
    });
  }

  createRegion(index?: number) {
    let data = {
      camera: this.camera
    };

    if(index!=undefined) {
      data['region'] = this.regionQueryResult.data[index]
    }

    const dialogRef = this.dialog.open(ZonesComponent, {
      panelClass: 'people-create-modal',
      backdropClass: 'people-create-backdrop',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  deleteEventRule(i: number) {
    this.eventRulesService.deleteEventRule(this.rulesQueryResult.data[i]._id).subscribe(() => {
      this.rulesQueryResult.data.splice(i, 1);
      this.snackBar.open('Event rule was successfully removed', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

  save() {
    this.cameraService.saveCamera(this.camera).subscribe(() => {
      this.snackBar.open('Camera was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    }, error => {
      this.snackBar.open('Camera could not be saved. Please try again later.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
