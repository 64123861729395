import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { ApiService, ApiEndpoint } from '../services/api/api.service';
import { Auth } from '../services/auth/auth.model';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(public userService: UserService, public router: Router,
    public storage: LocalStorageService, public api: ApiService,
    private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = request;

    if (this.isAuthenticationRequired(url)) {
      
      let auth: Auth = this.storage.get('AUTH');

      return new Observable(observer => {
        return next.handle(this.addToken(request, auth.session_token)).subscribe((httpEvent) => {
          observer.next(httpEvent);
        }, (err: HttpErrorResponse) => {
          observer.error(err);
          if (err.status == 401) {
            this.authService.logout().subscribe(() => {});
            this.router.navigate(['/']);
          }
        });
      });
    } else {
      return next.handle(request);
    }
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: string) {
    let headers = {};

    if (token) {
      headers['Authorization'] = token;
      let clone: HttpRequest<any> = request.clone({
        setHeaders: headers
      });
      return clone;
    }
    return request;
  }

  private isAuthenticationRequired(url: string) {
    let authenticatedPaths = [
      'account',
      'camera',
      'zone',
      'mailgroup',
      'user',
      'refresh',
      'logout',
      'device',
      'region',
      'eventrule',
      'config',
      'events',
      'people',
      'media',
      'face',
      'alert'
    ];

    for (let i = 0; i < authenticatedPaths.length; i++) {
      if (url.includes(authenticatedPaths[i])) {
        return true;
      }
    }
    return false;
  }
}
