<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Edit Camera</h1>
            <h2></h2>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="save()">Save changes</button>
        </div>
    </div>

    <div id="page-content">
        <div id="header" *ngIf="camera">
            <div id="basic-info">
                <div id="camera-info">
                    <label>Camera Name</label>
                    <input type="text" [(ngModel)]="camera.name" placeholder="Front door camera" />
                    <input type="text" [(ngModel)]="camera.uri" placeholder="rtsp://192.168.0.10/live/stream" />
                    <div>
                        <label>Device</label>
                        <select class="app-select" [(ngModel)]="camera.leaf_id">
                            <option></option>
                            <option
                                *ngFor="let device of deviceQueryResult.data" [value]="device._id">{{device.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div id="camera-images">
                <div id="images-header">
                    <h5>Regions</h5>
                    <a href="#"></a>
                </div>

                <div id="images-holder">
                    <div *ngIf="regionQueryResult">
                        <div
                            class="camera-image"
                            *ngFor="let region of regionQueryResult.data; let i = index;"
                            (click)="createRegion(i)">
                            <span>{{region.name}}</span>
                        </div>
                    </div>
                    <img
                        (click)="createRegion()"
                        src="../../../../../assets/images/icons/circle-add.svg" />
                </div>
            </div>
        </div>

        <div class="app-table">
            <button class="app-button" (click)="createEventRule()">Add rule</button>
            <div class="highlight-table container">
                <div class="row header">
                    <div class="col-4">Attribute</div>
                    <div class="col-4">Type</div>
                    <div class="col-2">Criteria Actions</div>
                    <div class="col-2">Actions</div>
                </div>
                <div id="table-data" *ngIf="rulesQueryResult">
                    <div class="row highlighted" *ngFor="let rule of rulesQueryResult.data; let i = index">
                        <div class="col-4">{{eventRulesAttributes[rule._id]['attributes']}}</div>
                        <div class="col-4">{{eventRulesAttributes[rule._id]['types']}}</div>
                        <div class="col-2">{{eventRulesAttributes[rule._id]['actions']}}</div>
                        <div class="col-2 actions">
                            <button
                                class="action-item"
                                (click)="createEventRule(i)">
                                <img src="../../../assets/images/icons/edit.svg" />
                                <span>Edit</span>
                            </button>

                            <button
                                class="action-item"
                                (click)="deleteEventRule(i)">
                                <img src="../../../assets/images/icons/trash.svg" />
                                <span>Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>