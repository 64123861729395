<div class="page livefeed">
    <div class="page-header">
        <h5>BOLO</h5>
        <span>Be on the lookout</span>
    </div>
    <div class="row">
        <div class="col-12 panel">
            <div class="panel-body">
                <div class="row profile-search">
                    <div class="col-2">
                        <button (click)="save()">Save</button>
                    </div>

                    <div class="col-4">
                        <select>
                            <option></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" id="history">
            <div class="row header">
                <div class="col-4"></div>
                <div class="col-4">Name</div>
                <div class="col-4">Action</div>
            </div>
            <div *ngFor="let profile of profiles">
                <div class="row highlighted">
                    <div class="col-4">
                        <img src="http://13.82.102.247:5010/get_image?file={{profile.images[0]}}" />
                    </div>
                    <div class="col-4">{{profile.name}}</div>
                    <div class="col-4">
                        <input type="checkbox" (change)="onChange($event, profile.id, profile.name)" />
                    </div>
                </div>
                <div class="row spacer">
                </div>
            </div>
        </div>
    </div>
</div>