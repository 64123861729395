<div id="region-create-holder">
    <div id="header">
        <h1>Region Settings</h1>
        <button (click)="remove()">delete</button>
    </div>

    <div id="body">
        <app-input
            [(value)]="region.name"
            [placeholder]="'e.g. around the door'"
            [label]="'Region Name'"></app-input>

        <app-select
            [options]="regionTypes"
            [(value)]="region.type"
            [label]="'Region Type'"></app-select>

        <div *ngIf="_camera">
            <app-roi
                [type]="region.type"
                [cameraWidth]="_camera.dimensions[0]"
                [cameraHeight]="_camera.dimensions[1]"
                [roi]="roi"
                [thumbnail]="_camera.thumbnail"
                (change)="roiChange($event)"></app-roi>
        </div>

        <div id="actions">
            <button id="cancel" (click)="cancel()">Cancel</button>
            <button id="save" (click)="save()">Save</button>
        </div>
    </div>
</div>