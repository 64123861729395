import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

  @Input()
  set report(data: any) {
    let graphData = [];
    console.log(data);
    if(data) {
      for(let i = 0; i < data['values'].length; i++) {
        let o = {
          'name': data['values'][i]['date'] + " ("+data['values'][i]['total']+")",
          'value': (data['values'][i]['total']/data['total']) * 100
        };
        graphData.push(o);
      }
    }
    this.data = graphData;
  }

  @Input()
  analysisResults: any;

  data: any[] = [];
  
  view: [number, number] = [400, 400];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showGridLines: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  colorScheme = {
    domain: [
      'rgb(249, 190, 0)',
      'rgb(80, 124, 182)',
      'rgb(107, 200, 205)',
      'rgb(255, 139, 79',
      'rgb(125, 94, 144)'
    ]
  };

  constructor() { }

  ngOnInit(): void { }

  onSelect(data: any): void { }

  onActivate(data: any): void { }

  onDeactivate(data: any): void { }

}
