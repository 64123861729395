<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Users</h1>
            <h2>Manage users for this account</h2>
        </div>

        <div id="page-options">
            <button class="app-button" routerLink="/user/create">Create</button>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
            </div>
            <div class="highlight-table container">
                <div class="row header">
                    <div class="col-5">Name</div>
                    <div class="col-5">Email</div>
                    <div class="col-2">Actions</div>
                </div>
                <div  *ngFor="let u of userQuery.data; let i = index;">
                    <div class="row highlighted">
                        <div class="col-5">{{u.name}}</div>
                        <div class="col-5">{{u.email}}</div>
                        <div class="col-2 actions">
                            <button
                                class="action-item"
                                (click)="editUser(i)">
                                <img src="../../../assets/images/icons/edit.svg" />
                                <span>Edit</span>
                            </button>

                            <button
                                class="action-item"
                                (click)="deleteUser(i)">
                                <img src="../../../assets/images/icons/trash.svg" />
                                <span>Delete</span>
                            </button>
                        </div>
                    </div>
                    <div class="row spacer">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>