<div class="page-header">
    <div id="title">
        <h1>Device Settings</h1>
    </div>
</div>

<div class="page">
    <div class="content-section">
        <div id="header">
            <div>
                <button class="app-button" (click)="save()">Save</button>
            </div>
        </div>
        <div class="body app-form">
            <div>
                <mat-form-field class="example-full-width" *ngIf="device">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="device.name">
                </mat-form-field>
            </div>

            <div class="app-form-field">
                <label>Model</label>
                <select class="app-select" (change)="onModelSelected()" [(ngModel)]="selectedModelId">
                    <option *ngFor="let model of models" value="{{model.id}}">{{model.name}}</option>
                </select>
                <p *ngIf="selectedModel">
                    {{selectedModel.description}}
                </p>
            </div>
        </div>
    </div>
</div>