import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { EnvService } from '../env/env.service';

export enum WebsocketMessageTypes {
  RECORD = 'record',
  EVENT = 'event'
}

export interface WebsocketMessage {
  type: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<WebsocketMessage>;

  constructor(private auth: AuthService, private envService: EnvService) {
    this.auth.$isLoggedIn.subscribe((isLoggedIn) => {
      if(isLoggedIn) {
        let token = this.auth.getAuthToken();
        let url = this.envService.WEBSOCKET + token['session_token'];
        this.messages = <Subject<WebsocketMessage>>this.connect(url).pipe(
          map(
            (response: MessageEvent): WebsocketMessage => {
              let data = JSON.parse(response.data)
              return data;
            }
          )
        );
      }
    })
  }

  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    let ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      error: (err: any) => {
        console.log("error during socket connection");
        console.log(err);
      },
      complete: null,
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
