import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-camera-autocomplete',
  templateUrl: './camera-autocomplete.component.html',
  styleUrls: ['./camera-autocomplete.component.scss']
})
export class CameraAutocompleteComponent implements OnInit {

  form = new FormControl();
  options: Camera[] = [];

  @Input()
  set value(camera: any) {
    if(camera) {
      this.form.setValue(camera);
    }
  }

  @Output()
  onChange: EventEmitter<Camera> = new EventEmitter<Camera>();

  constructor(private cameraService: CameraService) { }

  ngOnInit() {
    this.form.valueChanges.pipe(
      filter(text => text.length > 2),
      debounceTime(350),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.getCameras(value);
    })
  }

  displayFn(camera: Camera): string {
    return camera? camera.name: '';
  }

  onOptionSelected($event: any) {
    this.onChange.emit($event);
  }

  private getCameras(name: string) {
    this.cameraService.getCameras().subscribe((queryResult: QueryResult<Camera>) => {
      this.options = queryResult.data;
    })
  }

}
