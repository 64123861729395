<div class="page-signin">
    <div id="login-form-holder">
        <div id="login-form">

            <div id="header">
                <img src="assets/images/logo.png" />
                <h1>Sign into your account</h1>
                <h2>Welcome to your Guardsman AI Dashboard</h2>
            </div>

            <div class="forminput">
                <app-input
                    [label]="'Email Address'"
                    [type]="'text'"
                    [placeholder]="'Enter your email'"
                    [(value)]="auth.email"></app-input>
            </div>

            <div class="forminput">
                <app-input
                    [label]="'Password'"
                    [type]="'password'"
                    [placeholder]="'Enter your password'"
                    [(value)]="auth.pwd"></app-input>
            </div>

            <div id="remember-me">
                <div>
                    <input type="checkbox" />
                    <label>Remember me for 30 days</label>
                </div>
                <a href="#">Forgot password?</a>
            </div>

            <progress-button
                [loading]="isLoading"
                [text]="'SIGN IN'"
                (click)="login()"></progress-button>

            <!--<div id="contact">
                Dont' have an account? <br/>
                Contact us at <a href="www.contexual.io">www.contexual.io</a>
            </div>-->
        </div>

        <div id="footer">
            <span>Guardsman AI 2022</span>
            <!--<span>Powered by Contexual AI</span>-->
        </div>
    </div>
    <div id="login-image">

        <img src="assets/images/login-camera.png" />
    </div>
</div>