import { Injectable } from '@angular/core';
import { ApiService, ApiEndpoint } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LookoutService {

  constructor(private api: ApiService) { }

  addLookout(name: string) {
    let lookout = {
      "data": name
    }
    return this.api.post(ApiEndpoint.MIDDLE + '/lookout', lookout);
  }
}
