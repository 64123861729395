import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AlertGroup } from 'src/app/services/alert/alert.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-alertgroup-autocomplete',
  templateUrl: './alertgroup-autocomplete.component.html',
  styleUrls: ['./alertgroup-autocomplete.component.scss']
})
export class AlertgroupAutocompleteComponent implements OnInit {

  form = new FormControl();
  options: AlertGroup[] = [];

  @Input()
  set id(id: any) {
    if(id) {
      this.alertService.getAlertGroups({ _id: id }).subscribe((queryResult: QueryResult<AlertGroup>) => {
        if(queryResult.total > 0) {
          this.form.setValue(queryResult.data[0]);
        }
      })
    }
  }

  @Output()
  onChange: EventEmitter<AlertGroup> = new EventEmitter<AlertGroup>();

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.form.valueChanges.pipe(
      filter(text => text.length > 2),
      debounceTime(350),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.getAlertGroup(value);
    })
  }

  displayFn(alertGroup: AlertGroup): string {
    return alertGroup? alertGroup.name: '';
  }

  onOptionSelected($event: any) {
    this.onChange.emit($event);
  }

  private getAlertGroup(name: string) {
    this.alertService.getAlertGroups().subscribe((queryResult: QueryResult<AlertGroup>) => {
      this.options = queryResult.data;
    })
  }

}
