import { Component, Input, OnInit } from '@angular/core';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-camera-display',
  templateUrl: './camera-display.component.html',
  styleUrls: ['./camera-display.component.scss']
})
export class CameraDisplayComponent implements OnInit {

  camera: Camera;

  @Input()
  set cameraId(id: string) {
    if(id) {
      this.cameraService.getCameras({_id: id}).subscribe((queryResult: QueryResult<Camera>) => {
        if(queryResult.total > 0)
          this.camera = queryResult.data[0];
      });
    }
  }


  constructor(private cameraService: CameraService) { }

  ngOnInit(): void { }

}
