import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LiveComponent } from './components/live/live.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { AnalyzerComponent } from './components/analyzer/analyzer.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { LookoutComponent } from './components/lookout/lookout.component';
import { SeenComponent } from './components/seen/seen.component';
import { SeenSearchComponent } from './components/seen-search/seen-search.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ZonesComponent } from './components/camera/region-update/zones.component';
import { MailgroupComponent } from './components/mailgroup/mailgroup.component';
import { UsersComponent } from './components/users/users.component';
import { UserComponent } from './components/user/user.component';
import { DeviceSettingsComponent } from './components/device-settings/device-settings.component';
import { DeviceUpdateComponent } from './components/device-update/device-update.component';
import { RegionsListComponent } from './components/regions-list/regions-list.component';
import { EventRulesListComponent } from './components/event-rules-list/event-rules-list.component';
import { EventRuleUpdateComponent } from './components/camera/event-rule-update/event-rule-update.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { PeopleComponent } from './components/people/search/people.component';
import { PeopleViewComponent } from './components/people/view/people-view/people-view.component';
import { CameraSettingsComponent } from './components/camera/search/camera-settings.component';
import { CameraConfigComponent } from './components/camera/create/camera-config.component';
import { CameraViewComponent } from './components/camera/view/camera-view.component';
import { FacialAnalysisComponent } from './components/facial-analysis/facial-analysis.component';
import { ListRuleTemplatesComponent } from './components/rules/list-rule-templates/list-rule-templates.component';


const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
  { path: 'live', component: LiveComponent, canActivate: [AuthGuardService] },
  { path: 'timeline', component: TimelineComponent, canActivate: [AuthGuardService] },
  { path: 'analyzer', component: AnalyzerComponent, canActivate: [AuthGuardService] },
  { path: 'tracker', component: FacialAnalysisComponent, canActivate: [AuthGuardService] },

  // PEOPLE
  { path: 'people', component: PeopleComponent, canActivate: [AuthGuardService] },
  { path: 'people/:id', component: PeopleViewComponent, canActivate: [AuthGuardService] },

  { path: 'profiles', component: ProfilesComponent, canActivate: [AuthGuardService] },
  { path: 'profile/edit', component: ProfileEditComponent, canActivate: [AuthGuardService] },
  { path: 'profile/edit/:id', component: ProfileEditComponent, canActivate: [AuthGuardService] },

  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService] },
  { path: 'settings/cameras', component: CameraSettingsComponent, canActivate: [AuthGuardService] },
  { path: 'settings/cameras/create', component: CameraConfigComponent, canActivate: [AuthGuardService] },
  { path: 'settings/cameras/:id', component: CameraViewComponent, canActivate: [AuthGuardService] },
  { path: 'settings/lookout', component: LookoutComponent, canActivate: [AuthGuardService] },
  { path: 'settings/devices', component: DeviceSettingsComponent, canActivate: [AuthGuardService] },
  { path: 'settings/devices/:id', component: DeviceUpdateComponent, canActivate: [AuthGuardService] },
  { path: 'settings/regions', component: RegionsListComponent, canActivate: [AuthGuardService] },
  { path: 'settings/regions/create', component: ZonesComponent, canActivate: [AuthGuardService] },
  { path: 'settings/regions/:id', component: ZonesComponent, canActivate: [AuthGuardService] },
  { path: 'settings/event-rules', component: EventRulesListComponent, canActivate: [AuthGuardService] },
  { path: 'settings/event-rules/create', component: EventRuleUpdateComponent, canActivate: [AuthGuardService] },
  { path: 'settings/event-rules/:id', component: EventRuleUpdateComponent, canActivate: [AuthGuardService] },

  { path: 'rules/templates', component: ListRuleTemplatesComponent, canActivate: [AuthGuardService] },

  { path: 'seen', component: SeenComponent, canActivate: [AuthGuardService] },
  { path: 'seen/search/:id', component: SeenSearchComponent, canActivate: [AuthGuardService] },

  { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuardService] },
  { path: 'alertgroup/create', component: MailgroupComponent, canActivate: [AuthGuardService] },
  { path: 'alertgroup/:id', component: MailgroupComponent, canActivate: [AuthGuardService] },

  { path: 'settings/cameras/:cameraid/zone/create', component: ZonesComponent, canActivate: [AuthGuardService] },
  { path: 'settings/cameras/:cameraid/zone/:zoneid', component: ZonesComponent, canActivate: [AuthGuardService] },

  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'user/create', component: UserComponent, canActivate: [AuthGuardService] },
  { path: 'user/:userid', component: UserComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
