export const createUrl = (domain: string, path: string, query: any[] = []) => {
    if (domain.slice(-1) != '/' && path['0'] != '/') {
        domain += '/';
    }

    let url = [domain, path].join('');
    if (query.length > 0) {
        let params: string[] = [];
        query.forEach((q) => {
            params.push([q['key'], q['value']].join('='));
        });
        url += '?' + params.join('&');
    }
    return url;
}

export const getAttributeLabel = (attribute: string) => {
    switch (attribute) {
        case 'count':
            return 'Occupancy';
        case 'line':
            return 'Line Crossing';
        case 'face':
            return 'Facial Recognition';
        case 'loitering':
            return 'Loitering';
        case 'outzone':
            return 'Out Zone';
        default:
            return 'Unknown Attribute';
    }
}