<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Timeline</h1>
            <h2>Track and view detected events, alerts and objects.</h2>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
                <div *ngIf="query.type[0] == 'object'">
                    <mat-form-field id="tag-select">
                        <mat-chip-list #chipList aria-label="Tag selection">
                          <mat-chip
                            *ngFor="let tag of query.object_type"
                            selectable
                            removable
                            (removed)="remove(tag)">
                            {{tag}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            placeholder="Search tag..."
                            #tagInput
                            [formControl]="tagCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="onObjectTypeSelected($event)">
                          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                            {{tag}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div>
                    <app-select
                        [label]="'Rule'"
                        [options]="ruleTypes"
                        [(value)]="query.attribute"
                        (valueChange)="onRuleChange($event)"></app-select>
                </div>

                <div>
                    <mat-form-field color="accent" id="query-start" class="querydate">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateInput)="onDateSelect($event, 'start_date')"
                            [formControl]="startDate" />
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field color="accent" id="query-end" class="querydate">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepicker]="picker2" (dateInput)="onDateSelect($event, 'end_date')"
                            [formControl]="endDate" />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                
                <div>
                    <app-camera-autocomplete
                        (onChange)="onCameraSelected($event)"></app-camera-autocomplete>
                </div>
                <div>
                    <app-device-autocomplete
                        (onChange)="onDeviceSelected($event)"></app-device-autocomplete>
                </div>
            </div>
            <h1 class="no-data" *ngIf="!events || events.length == 0">No Events</h1>
            <div class="highlight-table container">
                <div class="row header" *ngIf="events && events.length > 0">
                    <div class="col-2">Date</div>
                    <div class="col-2">Camera</div>
                    <div class="col-4">Description</div>
                    <div class="col-2">Objects</div>
                    <div class="col-2">Actions</div>
                </div>
                <div id="table-data">
                    <div *ngFor="let event of events; let i = index;">
                        <div class="row highlighted" (click)="onEventSelected(i)">
                            <div class="col-2">{{ event.timestamp | date:'medium' }}</div>
                            <div class="col-2">{{ event.camera_name }}</div>
                            <div class="col-4">
                                <span *ngIf=" event.type=='frame' ">
                                    <app-event-description [event]="event"></app-event-description>
                                </span>
                                <span *ngIf=" event.type=='object' ">{{ event.object_type }}</span>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-2 actions">
                                <img src="../../../assets/images/view-clip.png" *ngIf="config.isDarkMode"/>
                                <img src="../../../assets/images/view-clip-dark.png" *ngIf="!config.isDarkMode"/>
                            </div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
                <table-pagination
                    [totalPages]="totalPages"
                    (change)="onPaginate($event)"></table-pagination>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

            <div class="content-section">
                
            </div>
        </div>
    </div>
</div>