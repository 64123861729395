import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-region-autocomplete',
  templateUrl: './region-autocomplete.component.html',
  styleUrls: ['./region-autocomplete.component.scss']
})
export class RegionAutocompleteComponent implements OnInit {

  form = new FormControl();
  options: Region[] = [];

  @Input()
  set value(region: any) {
    if(region) {
      this.form.setValue(region);
    }
  }

  @Input()
  set regionId(id: string) {
    if(id) {
      this.regionService.getRegions({_id: id}).subscribe((queryResult: QueryResult<Region>) => {
        this.form.setValue(queryResult.data[0]);
      })
    }
  }

  @Input()
  cameraId: string;

  @Output()
  onChange: EventEmitter<Region> = new EventEmitter<Region>();

  constructor(private regionService: RegionService) { }

  ngOnInit() {
    this.form.valueChanges.pipe(
      filter(text => text.length > 2),
      debounceTime(350),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.getCameras(value);
    })
  }

  displayFn(region: Region): string {
    return region? region.name: '';
  }

  onOptionSelected($event: any) {
    this.onChange.emit($event);
  }

  private getCameras(name: string) {
    this.regionService.getRegions({camera_id: this.cameraId}).subscribe((queryResult: QueryResult<Region>) => {
      this.options = queryResult.data;
    })
  }

}
