import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnvService } from 'src/app/services/env/env.service';
import { EventRule, EventRuleCriteria } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';
import { Face } from 'src/app/services/mesh/mesh.models';
import { MeshService } from 'src/app/services/mesh/mesh.service';
import { Report } from 'src/app/services/reports/reports.service';
import { WebsocketMessage, WebsocketMessageTypes, WebsocketService } from 'src/app/services/websocket/websocket.service';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit {

  event: Report;
  eventVideoSource: string;
  eventImage: string;
  eventCriteria: EventRuleCriteria;
  faces: Face[];
  isVideo: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Report,
    private eventRulesService: EventRulesService, private socket: WebsocketService,
    private meshService: MeshService, private envService: EnvService) {
    this.event = data['event'];

    this.eventRulesService.getEventRules({'_id': this.event.event_rule_id}).subscribe((eventRule) => {
      if(eventRule.total > 0) {
        let criteria = eventRule.data[0].matches.criteria.filter((criteria) => {
          if (criteria.id = this.event.criteria_id) {
            return criteria;
          }
        });

        if(criteria.length > 0) {
          this.eventCriteria = criteria[0];
        }
      }
    });

    if(this.event.record_name != '') {
      if(this.event.record_name.endsWith('mkv') || this.event.record_name.endsWith('mp4')) {
        this.eventVideoSource = this.envService.FILESERVER + '/files/' + this.event.record_name;
        this.isVideo = true;
      }
      else {
        this.eventImage = this.envService.FILESERVER + '/files/' + this.event.record_name;
      }

      this.meshService.getFaces(this.event.record_name).subscribe((faces) => {
        this.faces = faces;
        this.faces.forEach((face) => {
          face.images = face.images.map((img) => {
            return this.envService.MESH + '/v1/media/' + img;
          });
        });
      });
    }

    this.socket.messages.subscribe((msg: WebsocketMessage) => {
      if(msg.type == WebsocketMessageTypes.RECORD && msg.data['name'] == this.event['record_name']) {
        this.event['record']['status'] = msg.data['status'];
      }
    });
  }

  ngOnInit(): void {
  }

}
