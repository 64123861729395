import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { EnvService } from '../env/env.service';

export class Report {
  leaf_id: string;
  code_version: string;
  camera_id: string;
  region_id: string;
  criteria_id: string;
  event_rule_id: string;
  meta: any;
  timestamp: number;
  leaf_event_id: string;
  record_name: string;
  camera_name: string;
  leaf_name: string;
  attribute: string;
  region_name: string;
  record: {
    status: number;
    name: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private api: ApiService, private envService: EnvService) { }

  _convertEventsToQueryResult(result: any): QueryResult<Report> {
    let qr = new QueryResult<Report>();
    qr.total = result['count'];
    qr.data = result['values'];
    qr.data.forEach(event => {
      event.timestamp *= 1000;
    });
    return qr;
  }

  getReports(query: any) {
    const reportsPipe = () => (source: Observable<any>) =>
      new Observable<any>(observer => {
        source.subscribe((result) => {
          let events = result['values'] as Report[];
          events.forEach(event => {
            event.timestamp *= 1000;
          });
          observer.next({
            'count': result['count'],
            'events': events
          });
        });
      });
    return this.api.post(this.envService.REPORT + '/v1/events', query).pipe(reportsPipe());
  }

  getEvent(id: string) {
    const reportsPipe = () => (source: Observable<any>) =>
      new Observable<any>(observer => {
        source.subscribe((result) => {
          result.timestamp *= 1000;
          observer.next(result);
        },
        (err) => {
          observer.error(err);
        });
      });
    return this.api.get(this.envService.REPORT + '/v1/events/' + id).pipe(reportsPipe());
  }

  getPersonHistory(id: string, query: any) {
    const reportPipe = () => (source: Observable<any>) =>
      new Observable<any>(observer => {
        source.subscribe((result) => {
          observer.next(this._convertEventsToQueryResult(result));
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.REPORT + '/v1/events/people/history', query).pipe(reportPipe())
  }
}
