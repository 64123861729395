import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-listpicker',
  templateUrl: './listpicker.component.html',
  styleUrls: ['./listpicker.component.scss']
})
export class ListpickerComponent implements OnInit {

  @Output()
  change: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  available: any[];

  @Input()
  selected: any[];

  @Input()
  valueid: string;

  constructor() { }

  ngOnInit(): void { }

  getValue(o: any) {
    return o[this.valueid];
  }

  add(index: number) {
    let o = this.available[index];
    this.available.splice(index, 1);
    this.selected.push(o);
    this.change.emit(this.selected);
  }

  remove(index: number) {
    let o = this.selected[index];
    this.selected.splice(index, 1);
    this.available.push(o);
    this.change.emit(this.selected);
  }

}
