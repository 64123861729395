import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { Face, People } from './mesh.models';
import { EnvService } from '../env/env.service';
import { QueryResult } from 'src/app/utils/query-result';
import { createUrl } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root'
})
export class MeshService {

  constructor(private api: ApiService,
    private authSerivce: AuthService, private envService: EnvService) { }

  getPeople(query: any = {}) {
    let url = createUrl(
      this.envService.MESH, 'v1/people',
      Object.keys(query).map((key) => { return { key: key, value: query[key] }; }));

    const peoplePipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<People>>(observer => {
        source.subscribe((result) => {
          let people = result as QueryResult<People>;
          people.data.forEach((person) => {
            person.images = person.images.map((img) => {
              return this.envService.MESH + '/v1/media/' + img;
            })
          })
          observer.next(people);
        }, error => {
          observer.error();
        });
      });
    return this.api.get(url).pipe(peoplePipe())
  }

  getPerson(id: string) {
    const peoplePipe = () => (source: Observable<any>) =>
    new Observable<People>(observer => {
      source.subscribe((person: People) => {
        person.images = person.images.map((img) => {
          return this.envService.MESH + '/v1/media/' + img;
        })
        observer.next(person);
      }, error => {
        observer.error();
      });
    });

    let url = this.envService.MESH + '/v1/people/' + id;
    return this.api.get(url).pipe(peoplePipe())
  }

  getFaces(record_name: string) {
    //this.authSerivce.getAuthToken();

    let query = {
      record_name: record_name
    };

    let url = this.envService.MESH + '/v1/face';
    const peoplePipe = () => (source: Observable<any>) =>
      new Observable<Face[]>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error();
        });
      });
    return this.api.post(url, query).pipe(peoplePipe())
  }

  savePerson(form: FormData) {
    let url = this.envService.MESH + '/v1/people';
    const peoplePipe = () => (source: Observable<any>) =>
      new Observable<Face[]>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error();
        });
      });
    return this.api.postForm(url, form).pipe(peoplePipe())
  }

  deletePerson(id: string) {
    let url = this.envService.MESH + '/v1/people/' + id;
    const peoplePipe = () => (source: Observable<any>) =>
      new Observable<Face[]>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error();
        });
      });
    return this.api.delete(url).pipe(peoplePipe())
  }
}
