import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from  './material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LiveComponent } from './components/live/live.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { AnalyzerComponent } from './components/analyzer/analyzer.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { RoiComponent } from './features/roi/roi.component';
import { LookoutComponent } from './components/lookout/lookout.component';
import { SeenComponent } from './components/seen/seen.component';
import { SeenSearchComponent } from './components/seen-search/seen-search.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { ZonesComponent } from './components/camera/region-update/zones.component';
import { MailgroupComponent } from './components/mailgroup/mailgroup.component';
import { ListpickerComponent } from './features/listpicker/listpicker.component';
import { UsersComponent } from './components/users/users.component';
import { UserComponent } from './components/user/user.component';
import { DeviceSettingsComponent } from './components/device-settings/device-settings.component';
import { DeviceService } from './services/device/device.service';
import { ApiService } from './services/api/api.service';
import { DeviceServiceFactory } from './services/device/device.service.factory';
import { DeviceUpdateComponent } from './components/device-update/device-update.component';
import { CameraService } from './services/camera/camera.service';
import { CameraServiceFactory } from './services/camera/camera.service.factory';
import { RegionsListComponent } from './components/regions-list/regions-list.component';
import { RegionService } from './services/region/region.service';
import { RegionServiceFactory } from './services/region/region.service.factory';
import { EventRulesListComponent } from './components/event-rules-list/event-rules-list.component';
import { EventRulesService } from './services/event-rules/event-rules.service';
import { EventRulesServiceFactory } from './services/event-rules/event-rules.service.factory';
import { EventRuleUpdateComponent } from './components/camera/event-rule-update/event-rule-update.component';
import { ReportModule } from './modules/report/report.module';
import { CameraAutocompleteComponent } from './features/camera-autocomplete/camera-autocomplete.component';
import { DeviceAutocompleteComponent } from './features/device-autocomplete/device-autocomplete.component';
import { RegionAutocompleteComponent } from './features/region-autocomplete/region-autocomplete.component';
import { CameraDisplayComponent } from './features/camera-display/camera-display.component';
import { AlertgroupAutocompleteComponent } from './features/alertgroup-autocomplete/alertgroup-autocomplete.component';
import { EventModalComponent } from './modals/event-modal/event-modal.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { EventDescriptionComponent } from './features/event-description/event-description.component';
import { ProgressButtonComponent } from './features/progress-button/progress-button.component';
import { CameraComponent } from './features/camera/camera.component';
import { PeopleComponent } from './components/people/search/people.component';
import { PeopleAutocompleteComponent } from './features/people-autocomplete/people-autocomplete.component';
import { AppInputComponent } from './features/app-input/app-input.component';
import { AppSelectComponent } from './features/app-select/app-select.component';
import { PeopleViewComponent } from './components/people/view/people-view/people-view.component';
import { PeopleCreateComponent } from './components/people/create/people-create/people-create.component';
import { TablePaginationComponent } from './features/table-pagination/table-pagination.component';
import { CameraSettingsComponent } from './components/camera/search/camera-settings.component';
import { CameraConfigComponent } from './components/camera/create/camera-config.component';
import { CameraViewComponent } from './components/camera/view/camera-view.component';
import { EnvServiceProvider } from './services/env/env.service.provider';
import { EnvService } from './services/env/env.service';
import { FacialAnalysisComponent } from './components/facial-analysis/facial-analysis.component';
import { ListRuleTemplatesComponent } from './components/rules/list-rule-templates/list-rule-templates.component';
import { CameraTableComponent } from './modals/camera-table/camera-table.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LiveComponent,
    TimelineComponent,
    AnalyzerComponent,
    SettingsComponent,
    ProfilesComponent,
    ProfileEditComponent,
    RoiComponent,
    CameraSettingsComponent,
    CameraConfigComponent,
    LookoutComponent,
    SeenComponent,
    SeenSearchComponent,
    AlertsComponent,
    ZonesComponent,
    MailgroupComponent,
    ListpickerComponent,
    UsersComponent,
    UserComponent,
    DeviceSettingsComponent,
    DeviceUpdateComponent,
    RegionsListComponent,
    EventRulesListComponent,
    EventRuleUpdateComponent,
    CameraAutocompleteComponent,
    DeviceAutocompleteComponent,
    RegionAutocompleteComponent,
    CameraDisplayComponent,
    AlertgroupAutocompleteComponent,
    EventModalComponent,
    ConfirmDialogComponent,
    EventDescriptionComponent,
    ProgressButtonComponent,
    CameraComponent,
    PeopleComponent,
    PeopleAutocompleteComponent,
    AppInputComponent,
    AppSelectComponent,
    PeopleViewComponent,
    PeopleCreateComponent,
    TablePaginationComponent,
    CameraViewComponent,
    FacialAnalysisComponent,
    ListRuleTemplatesComponent,
    CameraTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    HttpClientModule,
    ReportModule,
    FontAwesomeModule
  ],
  providers: [
    EnvServiceProvider,
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {
      provide: DeviceService,
      useFactory: DeviceServiceFactory,
      deps: [ApiService, EnvService]
    },
    {
      provide: CameraService,
      useFactory: CameraServiceFactory,
      deps: [ApiService, EnvService]
    },
    {
      provide: RegionService,
      useFactory: RegionServiceFactory,
      deps: [ApiService, EnvService]
    },
    {
      provide: EventRulesService,
      useFactory: EventRulesServiceFactory,
      deps: [ApiService, EnvService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
