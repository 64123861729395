export class People {
    _id?: string;
    name: string;
    images: string[];
    face_ids: string[];
    createdAt: string;
    updatedAt: string;
}

export class Face {
    _id: string;
    name: string;
    images: string[];
    face_ids: string[];
}