<p *ngIf=" eventCriteria && (eventCriteria.attribute == 'outzone') ">
    <b>Out Zone - </b>A {{ eventCriteria.type }} has left the designated area.
</p>

<p *ngIf=" eventCriteria && (eventCriteria.attribute == 'count') ">
    <b>Overcrowding - </b>{{ event.meta.count }} {{ eventCriteria.type }}'s detected on {{ event.camera_name }}. Exceeds the limit of {{ event.meta.count_config }}.
</p>

<p *ngIf=" eventCriteria && (eventCriteria.attribute == 'line') ">
    <b>Line Crossing - </b>{{ eventCriteria.type }} crossed the line {{ eventCriteria.meta.line }}</p>

<p *ngIf=" eventCriteria && (eventCriteria.attribute == 'loitering') ">
    <b>Loitering - </b>{{ eventCriteria.type }} loitering in area for more than limit of {{ eventCriteria.meta.limit }} seconds.</p>

<p *ngIf=" _event && (_event.attribute == 'face') ">
    <b>Face Recognition - </b>A person was recognized on {{ event.camera_name }} matching
    <a href="/#/people/{{event.meta.person_id}}">{{ event.meta.person_name }}</a></p>