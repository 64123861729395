<div class="pagination">
    <span (click)="previous()">
        <img src="../../../assets/images/icons/left-arrow.svg" /> Previous
    </span>
    <div id="page-numbers">
        <div *ngFor="let page of pages" class="page-number">
            <span *ngIf="page!='el'" (click)="paginate(page)"
                [ngClass]="{'active': page==currentPage}">{{page}}</span>
            <span *ngIf="page=='el'">...</span>
        </div>
    </div>
    <span (click)="next()">
        Next <img src="../../../assets/images/icons/right-arrow.svg" />
    </span>
</div>