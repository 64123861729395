export class DeviceModel {
    name: string;
}

export class Device {
    _id: string;
    acc_id: string;
    name: string;
    primary_model: DeviceModel;
    secondary_models?: DeviceModel[];
}