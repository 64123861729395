import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { ApiService } from '../api/api.service';
import { Region } from './region.model';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getRegions(query: any = {}) {
    const regionPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<Region>>(observer => {
        source.subscribe((result) => {
          let queryResult = result as QueryResult<Region>;
          observer.next(queryResult);
        }, error => {
          observer.error(error);
        });
      });
    return this.api.post(this.envService.PLATFORM + '/region/query', query).pipe(regionPipe())
  }

  saveRegion(region: Region) {

    const regionPipe = () => (source: Observable<any>) =>
      new Observable<Region>(observer => {
        source.subscribe((result) => {

          if(result) {
            region._id = result['InsertedID'];
          }
          observer.next(region);
        }, error => {
          observer.error(error);
        });
      });

    if(region._id) {
      let url = this.envService.PLATFORM + `/region/${region._id}`;
      return this.api.put(url, region).pipe(regionPipe());
    }
    return this.api.post(this.envService.PLATFORM + '/region', region).pipe(regionPipe());
  }

  deleteRegion(id: string) {
    return this.api.delete(this.envService.PLATFORM + `/region/${id}`);
  }
}
