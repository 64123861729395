<div class="page">

    <div id="page-header">
        <div id="page-description">
            <h1>Edit User</h1>
        </div>

        <div id="page-options">
            <button class="app-button" (click)="save()">Save</button>
        </div>
    </div>

    <div id="page-content">
        <div>
            <mat-form-field class="field" *ngIf="user">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="user.name">
            </mat-form-field>

            <mat-form-field class="field" *ngIf="user">
                <mat-label>Email</mat-label>
                <input matInput type="email" [(ngModel)]="user.email">
            </mat-form-field>

            <mat-form-field class="field" *ngIf="user">
                <mat-label>Password</mat-label>
                <input matInput type="password" [(ngModel)]="user.pwd">
            </mat-form-field>
        </div>
    </div>
</div>