<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Event Rules</h1>
            <h2></h2>
        </div>
    </div>

    <div id="page-content">
        <div class="app-table">
            <div class="table-query">
                <div>
                </div>

                <div>
                    <button class="app-button" routerLink="/settings/event-rules/create">Create</button>
                </div>
            </div>
            <div class="highlight-table" *ngIf="eventRulesQueryResult">
                <div class="row header">
                    <div class="col-4">Camera</div>
                    <div class="col-4">Criteria</div>
                    <div class="col-4">Action</div>
                </div>
                <div id="table-data">
                    <div *ngFor="let rule of eventRulesQueryResult.data; let i = index;">
                        <div class="row highlighted">
                            <div class="col-4">
                                <app-camera-display [cameraId]="rule.camera_id"></app-camera-display>
                            </div>
                            <div class="col-4">{{rule.leaf}}</div>
                            <div class="col-4">
                                <mat-icon
                                    class="example-icon"
                                    [routerLink]="['/settings/event-rules', rule._id]">edit</mat-icon>

                                <button
                                    class="action-item"
                                    (click)="deleteEventRule(i)">
                                    <img src="../../../assets/images/icons/trash.svg" />
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                        <div class="row spacer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>