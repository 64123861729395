import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ReportsService, Report } from 'src/app/services/reports/reports.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EventModalComponent } from 'src/app/modals/event-modal/event-modal.component';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTags: string[] = ['person', 'car', 'backpack'];
  ruleTypes: any[] = [
    {"label": "All", "value": "ALL"},
    {"label": "Overcrowding", "value": "count"},
    {"label": "Loitering", "value": "loitering"},
    {"label": "Line Crossing", "value": "line"},
    {"label": "Facial Recognition", "value": "face"},
  ]

  totalEvents: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  events: Report[];
  selectedEvent: Report;
  eventVideoSource: string;
  picker1: Date;

  // graph values start
  single: any[];
  multi: any[];

  view: any[] = [400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = "Time";
  showYAxisLabel = true;
  yAxisLabel = "Alerts";

  colorScheme = {
    domain: ["#456960"]
  };

  persontag: boolean;

  query: any;
  startDate: FormControl;
  endDate: FormControl;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private reportsService: ReportsService, private activatedRoute: ActivatedRoute,
    private dialog: MatDialog, public config: ConfigService) {
  }

  ngOnInit(): void {
    this.currentPage = 1;
    this.pageSize = 10;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allTags.slice()));

    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.startDate = new FormControl(start);

    let end = new Date();
    end.setHours(23, 59, 59, 99);
    this.endDate = new FormControl(end);

    this.query = {
      'offset': (this.currentPage-1) * this.pageSize,
      'size': this.pageSize,
      'start_date': start.getTime()/1000,
      'end_date': end.getTime()/1000,
      'type': ['frame']
    };

    this.activatedRoute.queryParams.subscribe(params => {
        if(params['id']) {
          this.reportsService.getEvent(params['id']).subscribe((event) => {
            if(Object.keys(event).length>0) {
              this.selectedEvent = event;
              this.onEventSelected()
            }
          });
        }
    });

    this.getReports();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.query.object_type.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
    this.getReports();
  }

  remove(tag: string): void {
    const index = this.query.object_type.indexOf(tag.toLowerCase());

    if (index >= 0) {
      this.query.object_type.splice(index, 1);
    }

    if(tag == 'Person') {
      this.persontag = false;
    }
    this.getReports();
  }

  onTypeSelected(): void {
    if(this.query['type'][0] == 'frame') {
      delete this.query['object_type'];
    }
    else {
      this.query['object_type'] = ['person'];
    }
    this.getReports();
  }

  onObjectTypeSelected(event: MatAutocompleteSelectedEvent): void {
    this.query.object_type.push(event.option.viewValue.toLowerCase());
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.getReports();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  onRuleChange($event: any) {
    if($event == 'ALL') {
      delete this.query['attribute'];  
    }
    else {
      this.query['attribute'] = $event;
    }
    this.getReports();
  }

  getReports() {
    this.query['offset'] = (this.currentPage-1) * this.pageSize;
    this.reportsService.getReports(this.query).subscribe(events => {
      this.totalEvents = events['count'];
      this.events = events['events'];
      this.totalPages = Math.ceil(events['count']/this.pageSize);
    });
  }

  onDateSelect($event: any, type: string) {
    let date = new Date($event['value']);
    this.query[type] = date.getTime()/1000;
    this.getReports();
  }

  onEventSelected(index?: number) {
    if(index != undefined)
      this.selectedEvent = this.events[index];

    const dialogRef = this.dialog.open(EventModalComponent, {
      panelClass: 'event-modal',
      backdropClass: 'people-create-backdrop',
      data: {
        event: this.selectedEvent,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onCameraSelected($event: any) {
    this.query['cameras'] = [ $event['_id'] ];
    this.currentPage = 1;
    this.getReports();
  }

  onDeviceSelected($event: any) {
    this.query['devices'] = [ $event['_id'] ];
    this.currentPage = 1;
    this.getReports();
  }

  onPaginate($event: any) {
    this.currentPage = $event;
    this.getReports();
  }

  closeEventDrawer() {
    this.selectedEvent = undefined;
    this.eventVideoSource = '';
  }

}
