import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Device } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-device-autocomplete',
  templateUrl: './device-autocomplete.component.html',
  styleUrls: ['./device-autocomplete.component.scss']
})
export class DeviceAutocompleteComponent implements OnInit {

  form = new FormControl();
  options: Device[] = [];

  @Input()
  set value(device: any) {
    if(device) {
      this.form.setValue(device);
    }
  }

  @Output()
  onChange: EventEmitter<Device> = new EventEmitter<Device>();

  constructor(private deviceService: DeviceService) { }

  ngOnInit() {
    this.form.valueChanges.pipe(
      filter(text => text.length > 2),
      debounceTime(350),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.getCameras(value);
    })
  }

  displayFn(device: Device): string {
    return device? device.name: '';
  }

  onOptionSelected($event: any) {
    this.onChange.emit($event);
  }

  private getCameras(name: string) {
    this.deviceService.getDevices().subscribe((queryResult: QueryResult<Device>) => {
      this.options = queryResult.data;
    })
  }

}
