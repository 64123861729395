import { Injectable } from '@angular/core';
import { ApiService, ApiEndpoint } from '../api/api.service';
import { Observable } from 'rxjs';

export class Attribute {
  label: string;
  value: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

export class Seen {
  id: string;
  image: string;
  feature: string;
  attributes: Attribute[];
  type: string;
  trackid: string;
  lp?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeenService {

  constructor(private api: ApiService) { }

  getSeen() {
    const profileHandler = () => (source: Observable<any>) =>
      new Observable<Seen[]>(observer => {
        source.subscribe((result) => {
          if(result) {
            let profiles = [];
            result.forEach(element => {
              let p = new Seen();
              p.id = element['id'];
              p.image = element['image'];
              p.type = element['type'];
              p.trackid = element['trackid'];

              if(element['lp']) {
                p.lp = element['lp'];
              }

              if(element['name']) {
                p.name = element['name'];
              }
              /*p.feature = element['feature'];

              let attributes = [];
              element['attributes'].forEach(att => {
                attributes.push(new Attribute(att[0], att[1]))
              })
              p.attributes = attributes;*/
              profiles.push(p);
            });
            observer.next(profiles);
          }
        });
      });
    return this.api.get(ApiEndpoint.PROFILE + '/seen').pipe(profileHandler());
  }

  searchSeen(id: string) {
    const profileHandler = () => (source: Observable<any>) =>
      new Observable<Seen[]>(observer => {
        source.subscribe((result) => {
          if(result) {
            let profiles = [];
            result.forEach(element => {
              let p = new Seen();
              p.id = element['id'];
              p.image = element['image'];
              p.feature = element['feature'];
              profiles.push(p);
            });
            observer.next(profiles);
          }
        });
      });
    return this.api.get(ApiEndpoint.PROFILE + '/search/' + id).pipe(profileHandler());
  }
}
