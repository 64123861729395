import { ApiService } from "../api/api.service";
import { EventRulesService } from "./event-rules.service";
import { MockEventRulesService } from "./mock.event-rules.service";
import { EnvService } from "../env/env.service";

export function EventRulesServiceFactory(api: ApiService, envService: EnvService) {
    if(envService.mock) {
        return new MockEventRulesService(api, envService);
    }
    return new EventRulesService(api, envService);
}