import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryResult } from "src/app/utils/query-result";
import { EventRule } from "./event-rules.model";
import { EventRulesService } from "./event-rules.service";

@Injectable()
export class MockEventRulesService extends EventRulesService{
  
    getEventRules(query: any = {}) {
        return new Observable<QueryResult<EventRule>>(observer => {
            let eventRulesQueryResult = new QueryResult<EventRule>();
            eventRulesQueryResult.data = [];
            eventRulesQueryResult.data.push(
                {
                    _id: '01', camera_id: '01', leaf_id: '01',
                    template: false,
                    description: '',
                    matches: {
                        match: 'ANY',
                        criteria: [
                            {
                                id: '01',
                                type: 'person',
                                attribute: 'linecrossing',
                                region: ['01'],
                                meta: {
                                    limit: '3'
                                },
                                actions: [
                                    {
                                        type: 'alert',
                                    }
                                ]
                            }
                        ]
                    }
                }
            );
            eventRulesQueryResult.total = eventRulesQueryResult.data.length;
            observer.next(eventRulesQueryResult);
        });
    }
  
    saveEventRule(eventRule: EventRule) {
        return new Observable<EventRule>(observer => {
            observer.next(
                {
                    _id: '01', camera_id: '01', leaf_id: '01',
                    template: false,
                    description: '',
                    matches: {
                        match: 'ANY',
                        criteria: [
                            {
                                id: '01',
                                type: '',
                                attribute: 'person',
                                region: [],
                                meta: {
                                    limit: '3'
                                },
                                actions: [
                                    {
                                        type: 'alert',
                                    }
                                ]
                            }
                        ]
                    }
                }
            );
        });
    }
  }