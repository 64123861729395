import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { ConfigService } from './services/config/config.service';
import { User } from './services/user/user.model';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ais-webapp';
  
  user: User;
  subscription$: Subscription[];
  isLoggedIn: boolean;
  menuLinks = [
    // { title: 'Dashboard', path: '/live', icon: 'dashboard' },
    { title: 'Dashboard', path: '/live', icon: 'videocam' },
    { title: 'Timeline', path: '/timeline', icon: 'timeline' },
    // { title: 'Live Tracker', path: '/tracker', icon: 'person' },
    { title: 'People', path: '/people', icon: 'person' },
    { title: 'Cameras', path: '/settings/cameras', icon: 'videocam' },
    { title: 'Rules', path: '/rules/templates', icon: 'analytics' },
    { title: 'Devices', path: '/settings/devices', icon: 'devices' },
    // { title: 'Reports', path: '/reports', icon: 'timeline' },
    // { title: 'Profiles', path: '/profiles', icon: 'supervised_user_circle' },
    // { title: 'Seen', path: '/seen', icon: 'supervised_user_circle' }
  ];

  public constructor(public config: ConfigService, private userService: UserService,
    private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.subscription$ = [];

    this.subscription$.push(this.authService.$isLoggedIn.subscribe((isLoggedIn) => {
      if(isLoggedIn) {
        this.isLoggedIn = true;
        this.config.setIsMenuActive(true);
        this.userService.getUser().subscribe(() => {});
      }
      else {
        this.isLoggedIn = false;
      }
    }));

    this.subscription$.push(this.userService.$currentUser.subscribe(user => {
      if(user) {
        this.user = user;
      }
    }));
  }

  toggleDarkMode() {
    document.body.classList.toggle('dark-theme');
    this.config.toggleDarkMode();
  }

  logout() {
    this.authService.logout().subscribe(auth => {
      this.config.isMenuActive = false;
      this.router.navigate(['/']);
    });
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
