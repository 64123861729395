<div class="page">
    <div id="page-header">
        <div id="page-description">
            <h1>Live Events</h1>
            <h2>Track events and alerts as they happen in real-time</h2>
        </div>

        <div id="page-options">
            <!--<app-input
                [placeholder]="'search cameras'"
                [label]="'Search'"></app-input>
            <app-select
                [label]="'Most Recent Alerts'"></app-select>-->
        </div>
    </div>

    <div id="page-content">
        <div id="cameras">
            <div *ngIf="cameras && cameras.total>0">
                <div *ngFor="let camera of cameras.data">
                    <app-camera [camera]="camera"></app-camera>
                </div>
            </div>
        </div>

        <div id="event-alerts">
            <div id="event-alerts-header">
                <div>
                    <h3>Alerts</h3>
                    <p>Showing <b>all events</b> for today</p>
                </div>
                <div>
                    <a href="/#/timeline">See all Events</a>
                </div>
            </div>

            <div id="events-holder">
                <div class="event-item" *ngFor="let event of events; let i = index"
                    (click)="onEventSelected(i)">
                    <div>
                        <div>
                            <span id="camera-name">{{ event.camera_name }}</span>
                            <span id="event-date">{{event.timestamp | date:'medium'}}</span>
                        </div>
                    </div>

                    <div>
                        <div id="event-description">
                            <app-event-description [event]="event"></app-event-description>
                        </div>
                        <div>
                            <img src="../../../assets/images/view-clip.png" *ngIf="config.isDarkMode"/>
                            <img src="../../../assets/images/view-clip-dark.png" *ngIf="!config.isDarkMode"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>