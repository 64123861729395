import { Component, OnInit } from '@angular/core';
import { UserService, MailGroup } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/services/user/user.model';
import { QueryResult } from 'src/app/utils/query-result';
import { AlertGroup } from 'src/app/services/alert/alert.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-mailgroup',
  templateUrl: './mailgroup.component.html',
  styleUrls: ['./mailgroup.component.scss']
})
export class MailgroupComponent implements OnInit {

  alertGroup: AlertGroup;

  constructor(private alertService: AlertService, private route: ActivatedRoute, private snackBar: MatSnackBar,
    private auth: AuthService) {
    this.alertGroup = new AlertGroup();

    this.route.paramMap.subscribe(params => {
      if (params['params']['id']) {
        this.alertService.getAlertGroups({ _id: params['params']['id'] }).subscribe((results: QueryResult<AlertGroup>) => {
          if(results.total) {
            this.alertGroup = results.data[0];
          }
        });
      }
    });
  }

  ngOnInit(): void { }

  trackByFn(index: number, item: any) {
    return index;  
  }

  addEmail() {
    this.alertGroup.emails.push('');
  }

  removeEmail(index: number) {
    this.alertGroup.emails.splice(index, 1);
  }

  save() {
    this.alertService.saveAlertGroup(this.alertGroup).subscribe((alertGroup) => {
      this.alertGroup = alertGroup;
      this.snackBar.open('Alert group was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    }, error => {
      this.snackBar.open('Alert group could not be saved. Please try again.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
