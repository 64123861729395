export const EventAttributes = [
    {
        'id': 'count',
        'label': 'Overcrowding'
    },
    {
        'id': 'line',
        'label': 'Line Crossing'
    },
    {
        'id': 'face',
        'label': 'Facial Recognition'
    },
    {
        'id': 'loitering',
        'label': 'Loitering'
    }
];

export class EventRuleCriteriaAction {
    type: string;
    groups?: string[];

    constructor() {
        this.groups = [];
    }
}

export class EventRuleCriteria {
    id: string;
    type: string;
    attribute: string;
    region: string[];
    meta: {
        limit?: string;
        line?: string;
    };
    actions: EventRuleCriteriaAction[];

    constructor() {
        this.region = [];
        this.meta = {}
    }
}

export class EventRule {
    _id: string;
    camera_id: string;
    leaf_id: string;
    template: boolean;
    description: string;
    matches: {
        match: string;
        criteria: EventRuleCriteria[];
    }

    constructor() {
        this.template = false;
        this.description = '';
        this.matches = {
            match: 'ANY',
            criteria: []
        }
    }
}