import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading: boolean = false;
  auth: {
    email: string;
    pwd: string;
  } = { email: '', pwd: '' };

  redirect: string = '/live';
  redirectParams = {};

  constructor(private config: ConfigService, private router: Router,
    private authService: AuthService, private snackBar: MatSnackBar,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params['redirect']) {
        this.redirect = params['redirect'];
      }

      if (params['params']) {
        params['params'].split('&').forEach((pair: string) => {
          let split = pair.split('=');
          this.redirectParams[split[0]] = split[1];
        });
      }
    });
  }

  ngOnInit(): void { }

  login() {
    this.isLoading = true;
    this.authService.login(this.auth.email, this.auth.pwd).subscribe(auth => {
      this.isLoading = false;
      this.config.isMenuActive = true;
      this.router.navigate([this.redirect], { queryParams: this.redirectParams });
    },
      () => {
        this.isLoading = false;

        this.snackBar.open('Invalid email/password. Please try again.', 'dismiss', {
          duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
        });
      });
  }

}
