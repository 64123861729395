import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraService } from 'src/app/services/camera/camera.service';
import { FormControl, Validators } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { QueryResult } from 'src/app/utils/query-result';
import { Camera } from 'src/app/services/camera/camera.model';
import { Device } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Region } from 'src/app/services/region/region.model';
import { RegionService } from 'src/app/services/region/region.service';
import { EventRule, EventRuleCriteria, EventRuleCriteriaAction } from 'src/app/services/event-rules/event-rules.model';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';

@Component({
  selector: 'app-camera-config',
  templateUrl: './camera-config.component.html',
  styleUrls: ['./camera-config.component.scss']
})
export class CameraConfigComponent implements OnInit {

  camera: Camera;
  deviceQueryResult: QueryResult<Device>;

  listname: string;
  list: string[];
  listType: string;
  panelOpenState = false;
  roi: number[];
  personLimit: number;
  dwellTime: number;
  emailFormControl = new FormControl('', [
    Validators.required
  ]);
  limitFormControl = new FormControl('', [
    Validators.required
  ]);

  constructor(private route: ActivatedRoute, private cameraService: CameraService,
    private snackBar: MatSnackBar, private regionService: RegionService,
    private eventRulesService: EventRulesService,
    private deviceService: DeviceService, private dialogRef: MatDialogRef<CameraConfigComponent>) {
      this.route.paramMap.subscribe(params => {
        if (params['params']['id']) {
          this.cameraService.getCameras({ '_id': params['params']['id']}).subscribe((results: QueryResult<Camera>) => {
            if(results.total) {
              this.camera = results.data[0];
            }
          });
        }
        else {
          this.camera = new Camera();
          if (!this.camera.dimensions) {
            this.camera.dimensions = [0,0];
          }
        }
      });
    }

  ngOnInit(): void {
    this.deviceService.getDevices().subscribe((deviceQueryResult) => {
      this.deviceQueryResult = deviceQueryResult;
    })
  }

  getZones() { }

  roiChange($event) {
    this.roi = $event;
  }

  addlist() {
    if(this.listname.length > 0) {
      this.list.push(this.listname);
      this.listname = "";
    }
  }

  removelist(index: number) {
    this.list.splice(index, 1);
  }

  save() {
    this.camera.dimensions[0] = Number(this.camera.dimensions[0]);
    this.camera.dimensions[1] = Number(this.camera.dimensions[1]);
    this.cameraService.saveCamera(this.camera).subscribe((camera) => {
      this.snackBar.open('Camera was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });

      //FIXME: create default region for the new camera
      let region = new Region();
      region.name = `${camera.name} main region`;
      region.type = 'box';
      region.camera_id = camera._id;
      region.leaf_id = camera.leaf_id;
      region.points = [
        0, 0,
        camera.dimensions[0], 0,
        camera.dimensions[0], camera.dimensions[1],
        0, camera.dimensions[1]];
      this.regionService.saveRegion(region).subscribe(() => {});

      this.dialogRef.close(true);
    }, error => {
      this.snackBar.open('Camera could not be saved. Please try again later.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

  cancel() {
    this.dialogRef.close();
  }

}
