import { Component, OnInit } from '@angular/core';
import { UserService, Account, MailGroup } from 'src/app/services/user/user.service';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import { QueryResult } from 'src/app/utils/query-result';
import { AlertGroup } from 'src/app/services/alert/alert.model';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {


  alertGroupQuery: QueryResult<AlertGroup>;

  constructor(private alertService: AlertService, private router: Router) { }

  ngOnInit(): void {
    this.alertService.getAlertGroups().subscribe((results) => {
      this.alertGroupQuery = results;
    })
  }

  deleteGroup(index: number) {

  }
}
