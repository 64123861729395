import { Component, Input, OnInit } from '@angular/core';
import { Camera } from 'src/app/services/camera/camera.model';
import { CameraService } from 'src/app/services/camera/camera.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { EventRulesService } from 'src/app/services/event-rules/event-rules.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  iconOnline = faSignal;
  iconOffline = faSignal;

  @Input()
  camera: Camera;

  @Input()
  descriptionEnabled: boolean = true;

  cameraStatus: any;
  deviceStatus: any;

  constructor(private cameraService: CameraService,
    private deviceService: DeviceService, private eventRuleService: EventRulesService) { }

  ngOnInit(): void {
    if (this.descriptionEnabled) {
      this.cameraService.getCameraStatus(this.camera._id).subscribe((status: any) => {
        if(status && status['count']>0) {
          this.cameraStatus = status['values'][0];
          this.cameraStatus['attribute'] = this.eventRuleService.getEventAttribute(this.cameraStatus['attribute']).label;
        }
      });

      this.deviceService.getDeviceStatus(this.camera.leaf_id).subscribe((status: any) => {
        this.deviceStatus = status;
      });
    }
  }

}
