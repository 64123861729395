import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/services/user/user.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  user: User;
  constructor(private route: ActivatedRoute, private userService: UserService,
    private snackBar: MatSnackBar, private router: Router) {
      if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.user) {
        this.user = this.router.getCurrentNavigation().extras.state.user;
      } else {
        this.user = new User();
      }
    }

  ngOnInit(): void {
  }

  save() {
    this.userService.saveUser(this.user).subscribe((user) => {
      this.user = user;
      this.snackBar.open('User was successfully saved', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    }, error => {
      this.snackBar.open('User could not be saved. Please try again later.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
