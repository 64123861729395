import { ApiService } from "../api/api.service";
import { EnvService } from "../env/env.service";
import { MockRegionService } from "./mock.region.service";
import { RegionService } from "./region.service";

export function RegionServiceFactory(api: ApiService, envService: EnvService) {
    if(envService.mock) {
        return new MockRegionService(api, envService);
    }
    return new RegionService(api, envService);
}