import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { People } from 'src/app/services/mesh/mesh.models';
import { MeshService } from 'src/app/services/mesh/mesh.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-people-autocomplete',
  templateUrl: './people-autocomplete.component.html',
  styleUrls: ['./people-autocomplete.component.scss']
})
export class PeopleAutocompleteComponent implements OnInit {

  form = new FormControl();
  options: QueryResult<People> = {total: 0, data: []};

  @Input()
  set id(id: any) {
    if(id) {
      this.meshService.getPeople().subscribe((people) => {
        if(people.data.length > 0) {
          for(let i=0; i < people.data.length; i++) {
            if(people[i].face_ids.indexOf(id)>-1) {
              this.form.setValue(people[i]);
              break;
            }
          }
        }
      })
    }
  }

  @Output()
  onChange: EventEmitter<People> = new EventEmitter<People>();

  constructor(private meshService: MeshService) { }

  ngOnInit() {
    this.form.valueChanges.pipe(
      filter(text => text.length > 2),
      debounceTime(350),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.getPeople(value);
    })
  }

  displayFn(person: People): string {
    return person? person.name: '';
  }

  onOptionSelected($event: any) {
    this.onChange.emit($event);
  }

  private getPeople(name: string) {
    this.meshService.getPeople().subscribe((people) => {
      this.options = people;
    })
  }

}
