import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { Camera } from './camera.model';
import { EnvService } from '../env/env.service';
import { createUrl } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(private api: ApiService, private envService: EnvService) { }

  getCameras(query: any = {}) {
    
    const cameraPipe = () => (source: Observable<any>) =>
      new Observable<QueryResult<Camera>>(observer => {
        source.subscribe((result) => {
          let queryResult = result as QueryResult<Camera>;
          queryResult.data.map((camera) => {
            camera.thumbnail = `${this.envService.FILESERVER}/files/${camera._id}`;
          })
          observer.next(queryResult);
        }, error => {
          observer.error(error);
        });
      });
    
    let url = createUrl(this.envService.PLATFORM, 'camera/query', Object.keys(query).map((key) => {
      return {key: key, value: query[key]};
    }))
    return this.api.post(url, query).pipe(cameraPipe())
  }

  saveCamera(camera: Camera) {
    const cameraPipe = () => (source: Observable<any>) =>
      new Observable<Camera>(observer => {
        source.subscribe((result) => {

          if(result) {
            camera._id = result['InsertedID'];
          }

          observer.next(camera);
        }, error => {
          observer.error(error);
        });
      });

    if(camera._id) {
      let url = this.envService.PLATFORM + `/camera/${camera._id}`;
      return this.api.put(url, camera).pipe(cameraPipe());  
    }
    return this.api.post(this.envService.PLATFORM + '/camera', camera).pipe(cameraPipe());
  }

  deleteCamera(id: string) {
    return this.api.delete(this.envService.PLATFORM + `/camera/${id}`);
  }

  getCameraStatus(cameraId: string) {
    const cameraPipe = () => (source: Observable<any>) =>
      new Observable<Camera>(observer => {
        source.subscribe((result) => {
          observer.next(result);
        }, error => {
          observer.error(error);
        });
      });

    return this.api.get(this.envService.REPORT + '/v1/status/camera/' + cameraId).pipe(cameraPipe());
  }
}
