import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QueryResult } from 'src/app/utils/query-result';
import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { User } from './user.model';
import { EnvService } from '../env/env.service';

export class Account {
  id: string;
  name: string;
  cameras: any[];
}

export class MailGroup {
  _id: string;
  name: string;
  mailing_list: string[];

  public constructor(obj?: any) {
    if(obj) {
      Object.keys(obj).forEach((key) => {
        this[key] = obj[key];
      });
    }

    if(!this.mailing_list) {
      this.mailing_list = [];
    }
  }
}

const STORAGE_KEY = 'USER'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  $currentUser: BehaviorSubject<User>;
  constructor(private api: ApiService, private storage: LocalStorageService, private envService: EnvService) {
    this.$currentUser = new BehaviorSubject<User>(undefined);
  }

  getAccount() {
    const accountHandler = () => (source: Observable<string>) =>
      new Observable(observer => {
        return source.subscribe((result) => {
          let acc = new Account();
          acc.id = result['_id'];
          acc.name = result['name'];
          acc.cameras = result['cameras'];
          observer.next(acc);
        },
          (error) => { observer.error(error); });
      });
    return this.api.get(this.envService.PLATFORM + '/account').pipe(accountHandler());
  }

  saveAccount(account: Account) {
    return this.api.put(this.envService.PLATFORM + '/account', account);
  }

  getUser() {
    const userHandler = () => (source: Observable<any>) =>
      new Observable(observer => {
        return source.subscribe((result) => {
          let user = result as User;
          this.$currentUser.next(user);
          observer.next();
        },
          (error) => { observer.error(error); });
      });
    return this.api.get(this.envService.PLATFORM + '/user').pipe(userHandler());
  }

  getUsers(query: any, page: number, limit: number) {
    let url = this.envService.PLATFORM + `/user/query?page=${page}&limit=${limit}`;
    const accountHandler = () => (source: Observable<any>) =>
      new Observable<QueryResult<User>>(observer => {
        return source.subscribe((result) => {
          let userQuery = result as QueryResult<User>;
          observer.next(userQuery);
        },
          (error) => { observer.error(error); });
      });
    return this.api.post(url, query).pipe(accountHandler());
  }

  saveUser(user: User) {
    const userHandler = () => (source: Observable<any>) =>
    new Observable<User>(observer => {
      return source.subscribe((result) => {
        if(result && result['_id']) {
          user._id = result['_id'];
        }
        observer.next(user);
      },
        (error) => { observer.error(error); });
    });
    if(user._id) {
      return this.api.put(this.envService.PLATFORM + `/user/${user._id}`, user).pipe(userHandler());
    } else {
      return this.api.post(this.envService.PLATFORM + '/user', user).pipe(userHandler());
    }
  }

  deleteUser(id: string) {
    let url = this.envService.PLATFORM + `/user/${id}`;
    const accountHandler = () => (source: Observable<any>) =>
      new Observable<QueryResult<User>>(observer => {
        return source.subscribe((result) => {
          observer.next();
        },
          (error) => { observer.error(error); });
      });
    return this.api.delete(url).pipe(accountHandler());
  }

  getMailGroups() {
    const accountHandler = () => (source: Observable<any[]>) =>
      new Observable(observer => {
        return source.subscribe((result) => {
          let mg: MailGroup[] = [];
          if(result) {
            result.forEach(o => {
              let group = new MailGroup(o);
              mg.push(group);
            });
          }
          observer.next(mg);
        },
          (error) => { observer.error(error); });
      });
    return this.api.get(this.envService.PLATFORM + '/mailgroup').pipe(accountHandler());
  }

  saveMailGroup(mailgroup: MailGroup) {
    if(mailgroup._id)
      return this.api.put(this.envService.PLATFORM + '/mailgroup', mailgroup);
    else
      return this.api.post(this.envService.PLATFORM + '/mailgroup', mailgroup);
  }
}
