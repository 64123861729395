import { Component, OnInit } from '@angular/core';
import { ProfileService, Profile } from 'src/app/services/profile/profile.service';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  profiles: Profile[];
  constructor(private profileService: ProfileService, private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.profileService.getProfiles().subscribe((result) => {
      this.profiles = result;
    }, error => {
      this.snackBar.open('Profile could not be loaded. Please try again later.', 'dismiss', {
        duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
      });
    });
  }

}
