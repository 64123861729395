import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './components/report/report.component';
import { ReportRoutingModule } from './report-routing.module';
import { GraphComponent } from './features/graph/graph.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CustomMaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ReportComponent, GraphComponent],
  imports: [
    CommonModule,
    ReportRoutingModule,
    NgxChartsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ReportModule { }
