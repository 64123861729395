export class Auth {
    refresh_token: string;
    session_token: string;
    acc_id: string;
    refresh_expiry: number;
    session_expiry: number;
    user_id: string;

    constructor(token: any) {
        this.refresh_token = token['refresh_token'];
        this.session_token = token['session_token'];
        this.acc_id = token['acc_id'];
        this.refresh_expiry = new Date(token['refresh_expiry']).getTime();
        this.session_expiry = new Date(token['session_expiry']).getTime();
        this.user_id = token['user_id'];
    }
}